import { FormGenerator } from "@kehillahglobal/ui";
import React, { useState } from "react";
import { Notice } from "../../../../SharedComponents";


export default function TestRequestForm({
  handleCreateTestOrder,
  toEdit,
}) {
    const [showNotice, setShowNotice] = useState(true);

  const getDate = (date)=>{
    return date?.split("T")[0]
  }
  let fields = [
    {
      fieldType: FormGenerator.Fields.TAG,
      label: "Enter version number and press 'Enter' or 'Return' to add ",
      placeholder: "1.0.0",
      value: toEdit?.id ? toEdit?.version?.number : "",
      name: "number",
      required: true,
    },
    {
      fieldType: FormGenerator.Fields.DATE,
      label: "Deadline",
      placeholder: "By what date do you need this completed ?.",
      value: toEdit?.id ? getDate(toEdit?.deadline) : "",
      name: "deadline",
      required: true,
    },
    {
      fieldType: "file",
      label: "Testing sheet(accepted format is .csv)",
      name: "testing_sheet",
      accepts: [".csv", ".xls", ".xlsx"],
      required: true,
      value: toEdit?.id ? toEdit?.testing_sheet : "",
      placeholder: "supported format (.csv)",
    },
    {
      fieldType: "input",
      label: "Version URL",
      placeholder: "http://companyxyzbeta.tech",
      value: toEdit?.id ? toEdit?.url : "",
      name: "url",
      required: false,
    },
    {
      fieldType: "file",
      label: "software File(accepted format is .zip)",
      name: "file",
      accepts: [".zip"],
      required: false,
      value: toEdit?.id ? toEdit?.file : "",
      placeholder: "select a software zip for this version",
    },
    {
      fieldType: FormGenerator.Fields.RICHTEXT,
      label: "Extra Version Instructions For Testers",
      placeholder: "testing instructions here",
      value: toEdit?.id ? toEdit?.instructions : "",
      name: "instructions",
      required: false,
    },
  ];
  return (
    <div>
      <Notice
        notice={showNotice}
        handleClose={() => setShowNotice(!showNotice)}
      />
      <FormGenerator
        title={""}
        subtitle={""}
        onSubmit={(data, reset) => {
          handleCreateTestOrder(data, reset);
        }}
        formSubmitBtnText={"Save"}
        elevation={0}
        fields={toEdit?.id ? fields?.slice(1) : fields}
      />
    </div>
  );
}
