import React from "react";
import { InformationCircleIcon, XIcon } from "@heroicons/react/outline";
import { goTo } from "../../utils/utils";

const Notice = ({ notice, handleClose }) => {
  const link =
    "https://docs.google.com/spreadsheets/d/1hYhjj3mx2EqZS0BM8R5Wv_vCR7f_Ne75KeqZx2ZkJVw/edit?usp=sharing";
  const downloadCsv = () => {
    goTo(link)
  };
  return (
    <>
      <div className={`${!notice ? "hidden" : ""} px-6 w-full max-w-2xl py-3`}>
        <div className="bg-yellow-100 rounded-md p-4 space-y-4">
          <div className="flex flex-row items-center justify-between w-full">
            <div className="flex flex-row space-x-2 items-center">
              <span className="font-semibold">Note</span>
              <InformationCircleIcon className="text-black w-6 h-6" />
            </div>

            <div>
              <XIcon
                className="w-6 h-6 text-black cursor-pointer hover:scale-105"
                onClick={() => handleClose()}
              />
            </div>
          </div>

          <div class>
            <ul>
              <li className="text-xs font-medium">
                All testing sheets must follow the exact sample csv format -{" "}
                {
                  <span
                    onClick={() => downloadCsv()}
                    className="text-nice-blue cursor-pointer hover:underline"
                  >
                    view sample
                  </span>
                }
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notice;
