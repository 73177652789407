import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./Success.css";
import { saveUserInfoAction, setPrefixAction } from "../../store/actions";
import { formApiCall } from "../../utils/api";
import { LOGIN } from "../../utils/urls";
import { parseJSON } from "../../utils/utils";
import {
  LOGIN_AS_COMPANY_ADMIN,
  LOGIN_AS_TESTER,
  SIGNUP_AS_TESTER,
  TESTER_PREFIX,
} from "../../utils/constant";
import { toast } from "react-toastify";
import ActivityIndicator from "../../SharedComponents/Loader.js/ActivityIndicator";

export default function Success() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  useEffect(() => {
  
    // eslint-disable-next-line no-unused-vars
    const [url, params] = window.location.href.toString().split("?");
    if (params) {
      // eslint-disable-next-line no-unused-vars
      const [paramName, paramValue] = params.split("=");

      const decodedData = parseJSON(atob(paramValue));
      const tokenId = decodedData?.tokenId;
      const isCreatingCompany = localStorage.getItem("createCompany");
      const isTesterSignUp = parseJSON(localStorage.getItem(SIGNUP_AS_TESTER));
      const isTesterLogin = parseJSON(localStorage.getItem(LOGIN_AS_TESTER));
      const isCompanyLogin = parseJSON(
        localStorage.getItem(LOGIN_AS_COMPANY_ADMIN)
      );

      formApiCall(LOGIN, {
        idToken: tokenId,
        is_tester: isTesterSignUp ? "True" : "False",
        is_agent: isCreatingCompany ? "True" : "False",
      }).then((res) => {
        if (res?.success) {
          dispatch(saveUserInfoAction(res?.data));

            const lastVisited = localStorage.lastVisited;
            if (lastVisited) {
              navigate(lastVisited);
              return;
            }

          // if creating a company
          if (isCreatingCompany) {
            navigate("/register/company");
            return;
          }
          if (isTesterSignUp) {
            navigate("/register/tester");
            return;
          }

          if (res?.data?.is_testing_agent && res?.data?.is_company_admin) {
            if (isTesterLogin) {
              dispatch(setPrefixAction(TESTER_PREFIX));
              localStorage.removeItem(LOGIN_AS_TESTER);
              localStorage.setItem("prefix", TESTER_PREFIX)
              return (window.location.href = "/" + TESTER_PREFIX + "/home");
            } else if (isCompanyLogin) {
              navigate("/company/account/selection");
            }
          } else if (isTesterLogin) {
            if (res?.data?.is_testing_agent) {
              dispatch(setPrefixAction(TESTER_PREFIX));
              localStorage.setItem("prefix", TESTER_PREFIX);
              return (window.location.href = "/" + TESTER_PREFIX + "/home");
            } else {
              navigate("/register/tester");
              return;
            }
          } else if (isCompanyLogin) {
            if (res?.data?.is_company_admin) {
              navigate("/company/account/selection");
              return;
            } else {
              navigate("/register/company");
              return;
            }
          } else {
            navigate("/account/type/confirmation");
          }
        } else {
          toast.error(res?.error);
        }
      });
    }
  }, [dispatch, navigate]);

  return <ActivityIndicator text={"Preparing For You 🤗"} />;
}
