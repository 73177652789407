import { NoItems, Table } from "@kehillahglobal/ui";
import React, { useEffect, useState } from "react";
import { Pagination } from "../../../SharedComponents";
import Loader from "../../../SharedComponents/Loader.js/Loader";
import ProjectStatus from "../../../SharedComponents/ProjectInfo/components/ProjectStatus";
import { formApiCall } from "../../../utils/api";
import { getQuote } from "../../../utils/Quotes";
import { LIST_AUDIT_LOGS } from "../../../utils/urls";
import { humanReadableDate, isEmpty } from "../../../utils/utils";

function CompanyAudits() {
  const [logs, setLogs] = useState([])
   const [itemsDisplayed, setItemsDisplayed] = useState([]);

  useEffect(() => {
    formApiCall(LIST_AUDIT_LOGS, {}).then(res=>{
      if(res?.success){
        setLogs(res?.data)
      }
    })
  }, [])
  
  const prepareTableData = () => {
    return itemsDisplayed?.map((log, index) => [
      <p>{humanReadableDate(log?.created_at, true)}</p>,
      <p>{log?.user?.name}</p>,
      <p>{log?.user?.email}</p>,
      <ProjectStatus status={log?.operation} />,
      <p>{log?.activity}</p>,
    ]);
  };

  return !isEmpty(logs) ? (
    <>
      <h1 className="font-bold text-4xl tracking-tighter mb-10">Audit Logs</h1>
      <div>
        {isEmpty(logs) ? (
          <NoItems text={getQuote()} />
        ) : (
          <>
            <Table
              columns={["Created At", "User", "Email", "Type", "Activity"]}
              data={prepareTableData()}
              hoverAnimation={false}
            />
            <div className="mt-7">
              <Pagination
                noOfItems={logs?.length}
                items={logs}
                setItemsDisplayed={setItemsDisplayed}
              />
            </div>
          </>
        )}
      </div>
    </>
  ):<Loader />;
}

export default CompanyAudits;
