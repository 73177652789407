import React, { useState } from "react";
import "./Home.css";
import {
  CashIcon,
  CheckIcon,
  InformationCircleIcon,
  ViewListIcon,
  LoginIcon,
  UserAddIcon,
} from "@heroicons/react/solid";
import { Button } from "../../SharedComponents";
import landing from "../../assets/images/landing.png";
import { HomeMenu } from "./component/Menu";
import { AUTH_URL } from "../../utils/urls";
import {
  COMPANY_PREFIX,
  LOGIN_AS_COMPANY_ADMIN,
  LOGIN_AS_TESTER,
  SIGNUP_AS_TESTER,
  TESTER_PREFIX,
} from "../../utils/constant";
import { useSelector } from "react-redux";

function Home() {
  const [showLoginOptions, setShowLoginOptions] = useState(false);
  const user = useSelector(state=>state.user)



  const gotoDashboard = ()=>{
    let prefix = localStorage.prefix;
    if (prefix ===TESTER_PREFIX){
      window.location.href = "/" + TESTER_PREFIX + "/home";
    }
    else if(prefix === COMPANY_PREFIX){
      window.location.href = "/" + COMPANY_PREFIX + "/dashboard";
    }
  }
  const hiddenMenuRoutes = [
    {
      name: "about",
      icon: <InformationCircleIcon className="h-6 w-6 text-gray-900" />,
      redirect: "/about",
    },
    {
      name: "service",
      icon: <ViewListIcon className="h-6 w-6 text-gray-900" />,
      redirect: "/service",
    },
    {
      name: "pricing",
      icon: <CashIcon className="h-6 w-6 text-gray-900" />,
      redirect: "/pricing",
    },
    {
      name: "Become A Tester",
      icon: <UserAddIcon className="h-6 w-6 text-gray-900" />,
      onClick: () => {
        localStorage.setItem(SIGNUP_AS_TESTER, "True");
        window.location.href = AUTH_URL;
      },
    },
    {
      name: "Login as an Admin",
      icon: <LoginIcon className="h-6 w-6 text-gray-900" />,
      onClick: () => {
        localStorage.setItem(LOGIN_AS_TESTER, true);
        window.location.href = AUTH_URL;
      },
    },
    {
      name: "Login as a Tester",
      icon: <LoginIcon className="h-6 w-6 text-gray-900" />,
      onClick: () => {
        localStorage.setItem(LOGIN_AS_COMPANY_ADMIN, true);
        window.location.href = AUTH_URL;
      },
    },
  ];

  const Checks = ({ title }) => {
    return (
      <div className="flex flex-row space-x-4 items-center">
        <CheckIcon className="w-6 h-6 text-green-600" />
        <div className="tracking-tighter font-semibold text-gray-900 text-sm">
          {title}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="overflow-x-hidden flex flex-col w-screen z-30">
        <div className="navigation flex flex-row items-center justify-between md:px-20 py-10 px-10">
          <div className="font-bold text-fuchsia-900 text-4xl">
            Testable<span className="text-pink-500">HQ</span>
          </div>

          <div className="lg:hidden absolute right-10 z-50">
            <HomeMenu routes={hiddenMenuRoutes} />
          </div>

          <div className="hidden lg:flex flex-row items-center space-x-6 cursor-pointer">
            {}
            <div className="hover:text-fuchsia-900 font-semibold text-md">
              Services
            </div>
            <div className="hover:text-fuchsia-900 font-semibold text-md">
              About
            </div>
            <div className="hover:text-fuchsia-900 font-semibold text-md">
              Pricing
            </div>
            <div className="hover:text-fuchsia-900 font-semibold text-md">
              Contact
            </div>
            <div
              className="hover:text-fuchsia-900 font-bold text-md"
              onClick={() => {
                localStorage.setItem(SIGNUP_AS_TESTER, "True");
                window.location.href = AUTH_URL;
              }}
            >
              Become A Tester
            </div>
          </div>
          {!user?.id ? (
            <div className="hidden lg:flex flex-row space-y-2 items-center flex-wrap">
              <Button
                name="Login"
                onClick={() => setShowLoginOptions(!showLoginOptions)}
                fill={false}
                style={{ width: 100 }}
              />
              {showLoginOptions && (
                <div className="elevate-float login-options-container">
                  <div
                    className="hover:text-fuchsia-900 font-semibold text-md"
                    onClick={() => {
                      localStorage.setItem(LOGIN_AS_TESTER, true);
                      window.location.href = AUTH_URL;
                    }}
                  >
                    Login as a Tester
                  </div>
                  <div
                    className="hover:text-fuchsia-900 font-semibold text-md"
                    onClick={() => {
                      localStorage.setItem(LOGIN_AS_COMPANY_ADMIN, true);
                      window.location.href = AUTH_URL;
                    }}
                  >
                    Login as an Admin
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="hidden lg:flex flex-row space-y-2 items-center flex-wrap">
              <Button
                name="Dashboard"
                onClick={() => gotoDashboard()}
                fill={false}
                style={{ width: 100 }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="w-screen lg:px-20 px-10 py-10 lg:py-20 flex flex-row flex-wrap items-center justify-center lg:space-x-6 box-border ">
        <div className="max-w-md w-full space-y-10">
          <div className="font-black tracking-tighter text-6xl">
            Testing <span className="text-pink-500">service</span> you can trust
          </div>

          <div className="text-gray-400 text-md font-medium">
            Your day-to-day maintenance tasks, simplified. TestableHQ, so you
            can spend less time debugging and more time getting things done
          </div>

          <div className="space-y-1">
            <Checks title={"Outsource test in a smart way"} />
            <Checks title={"Zero hidden cost"} />
            <Checks title={"Transparency of software testing"} />
          </div>
          <div style={{ display: "flex" }}>
            <Button
              name={"Get Started As Company"}
              fill={true}
              onClick={() => {
                localStorage.createCompany = true;
                window.location.href = AUTH_URL;
              }}
            />
          </div>
        </div>

        <div className="">
          <img alt="landing" className="w-full h-full" src={landing} />
        </div>
      </div>
    </>
  );
}

export default Home;
