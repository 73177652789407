import {
  FolderIcon,
} from "@heroicons/react/outline";
import { NoItems, Table } from "@kehillahglobal/ui";
import React, { useEffect, useState } from "react";
import Card from "../../../SharedComponents/card/Card";
import Header from "../../../SharedComponents/Header/Header";
import Money from "../../../SharedComponents/icons/Money";
import Loader from "../../../SharedComponents/Loader.js/Loader";
import ProjectStatus from "../../../SharedComponents/ProjectInfo/components/ProjectStatus";
import { formApiCall } from "../../../utils/api";
import { getQuote } from "../../../utils/Quotes";
import { GET_TESTER_DASHBOARD_DATA } from "../../../utils/urls";
import { humanReadableDate, isEmpty, shortenMe } from "../../../utils/utils";
import './TestingAgentDashboard.css'

function TestingAgentDashboard() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    formApiCall(GET_TESTER_DASHBOARD_DATA, {}).then((res) => {
          setLoading(false);
      if (res?.success) {
        setData(res?.data);
      }
    });
  }, []);

  const stats = [
    {
      title: "Projects",
      count: data?.projects_count || 0,
      icon: <FolderIcon className="h-6 w-7" color="white" />,
      primaryColor: "#E67D21",
      secondaryColor: "#D35400",
      onClick: () => alert("hello"),
    },
    {
      title: "Features",
      count: data?.feature_count || 0,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="white"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z"
          />
        </svg>
      ),
      primaryColor: "#3498DB",
      secondaryColor: "#007aff",
      onClick: () => alert("hello"),
    },
    {
      title: "Earnings",
      count: shortenMe(data?.total_earnings||"0.0") ,
      icon: <Money color={"white"}/>,
      primaryColor: "#229954",
      secondaryColor: "#1E8449",
      onClick: () => alert("hello"),
    },
  ];
  const prepareLatestOrdersTableData = () => {
    return (data?.latest_features || []).map((item, index) => [
      <p>{index + 1}</p>,
      <p>{humanReadableDate(item?.created_at)}</p>,
      <p>{humanReadableDate(item?.deadline)}</p>,
      <p>{item?.company}</p>,
      <p>{item?.project}</p>,
      <p>v{item?.version}</p>,
      <p>{item.feature}
      </p>,
      <ProjectStatus status={item?.status || "Pending"} />,
    ]);
  };
  return loading? <Loader />:(
    <>
      <div className="grid-repeat-3">
        {stats?.map((item) => {
          return (
            <Card
              title={item?.title}
              count={item?.count}
              primaryColor={item?.primaryColor}
              secondaryColor={item?.secondaryColor}
              onClick={item?.onClick}
              icon={item?.icon}
            />
          );
        })}
      </div>

      <div className="mt-10">
        <Header text={"Test Feature Highlights"} size={4} />
        {isEmpty(data?.latest_features) ? (
          <NoItems text={getQuote()} />
        ) : (
          <Table
            columns={[
              "#",
              "Date",
              "Deadline",
              "Company",
              "Project",
              "Order Version",
              "Feature",
              "Status",
            ]}
            data={prepareLatestOrdersTableData()}
            hoverAnimation={false}
            tableClass={"tester-dash-features-table"}
          />
        )}
      </div>
    </>
  );
}

export default TestingAgentDashboard;
