import { FormGenerator } from "@kehillahglobal/ui";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateAdminInvitationsLocallyAction } from "../../../store/actions";
import { formApiCall } from "../../../utils/api";
import { CREATE_COMPANY_USER_INVITATION, UPDATE_COMPANY_AGENT_INVITATION } from "../../../utils/urls";

export default function CompanyAdminInvitationForm({ toEdit, close, toggleShowMoreOptions,setChanger }) {
  const invitations = useSelector((state) => state.invitations);
  const dispatch = useDispatch();

  const handleCreateVersion = (data, reset) => {
    data.domain = window.location.origin;
    if(toEdit?.id){
      data.id = toEdit?.id

    }
    formApiCall(toEdit?.id ? UPDATE_COMPANY_AGENT_INVITATION:CREATE_COMPANY_USER_INVITATION, data).then((res) => {
      toggleShowMoreOptions();
      if (res?.success) {
        let newInvitations = invitations || [];
        if (toEdit?.id) {
          newInvitations = newInvitations?.filter(
            (item) => item?.id !== res?.data?.id
          );
        }
        newInvitations = [res?.data, ...newInvitations];
        dispatch(updateAdminInvitationsLocallyAction(newInvitations))
        close();
        toast.success("Admin invitation successfully sent.");
        if(setChanger){
          setChanger()
        }
      } else {
                close();
        toast.error(res?.error);
      }
    });
  };
  return (
    <div>
      <FormGenerator
        title={""}
        subtitle={""}
        onSubmit={(data, reset) => handleCreateVersion(data, reset)}
        formSubmitBtnText={toEdit?.id ? "Update Invitation" : "Send Invitation"}
        elevation={0}
        fields={[
          {
            fieldType: FormGenerator.Fields.INPUT,
            label: "Name i.e. (The full name of the invitee)",
            placeholder: "John Doe",
            value: toEdit?.id ? toEdit?.full_name : "",
            name: "full_name",
            required: true,
          },
          {
            fieldType: FormGenerator.Fields.INPUT,
            label: "Email",
            placeholder: "john.doe@icloud.com",
            value: toEdit?.id ? toEdit?.email : "",
            name: "email",
            required: true,
          },
        ]}
      />
    </div>
  );
}
