export const HOST =window.location.hostname === "localhost"
    ? "http://localhost:8000"
    : "https://testable-hq-dev.herokuapp.com";

export const BASE_URL = HOST;

const port = window.location.port;
const protocol = window.location.protocol;
const domain = window.location.hostname;

const local = `${protocol}//${domain}:${port}`;
const live = `${protocol}//${domain}`;

let DOMAIN = window.location.hostname === "localhost" ? local : live

const payloadData = JSON.stringify({
  appLogo: "https://i.postimg.cc/25QYx1ST/logooo.png",
  appName: "TestableHQ Auth",
  redirectTo: `${DOMAIN}/success/?entry=`,
  theme: {
    primaryColor: "#007aff",
    formColor: "#007aff",
    whiteText: "#f9f9f9",
    darkText: "#010101",
    font: "",
  },
});
const encodedString = btoa(payloadData);

export const AUTH_URL = `https://auth-dev.deal.partners/login/?entry=${encodedString}`;

// BACKEND AUTH
export const LOGIN = `${BASE_URL}/auth.login`;
export const LOGOUT = `${BASE_URL}/auth.logout`;
export const WHOAMI = `${BASE_URL}/auth.whoami`;

// PROJECT ENDPOINTS

export const CREATE_PROJECT = BASE_URL+ "/projects.create"
export const CREATE_COMPANY = BASE_URL+'/companies.create';
export const CREATE_REVIEW = BASE_URL+'/reviews.create';
export const CREATE_PROJECT_VERSION = BASE_URL + "/project.versions.create";
export const ORDER_A_TEST = BASE_URL + "/order.tests.create";
export const CREATE_COMPANY_USER_INVITATION = BASE_URL + "/company.agent.invitation.create";
export const CREATE_BILL = BASE_URL + "/bill.create";
export const CREATE_TEST_FEATURE = BASE_URL + "/test.feature.create";
export const UPLOAD_MEDIA = BASE_URL + "/media.create";
export const CREATE_FEATURE_TEST_MEDIA = BASE_URL + "/feature.tester.media.create";
export const ACCEPT_COMPANY_INVITATION = BASE_URL + "/company.agent.invitation.accept";

export const DELETE_PROJECT_VERSION = BASE_URL + "/project.versions.delete";
export const DELETE_PROJECT = BASE_URL + "/projects.delete";
export const DELETE_REVIEW = BASE_URL + "/reviews.delete";
export const DELETE_COMPANY_AGENT_INVITATION = BASE_URL + "/company.agent.invitation.delete";
export const DELETE_TEST_ORDER_INFO = BASE_URL + "/order.tests.delete";
export const DELETE_TEST_FEATURE = BASE_URL + "/test.feature.delete";
export const DELETE_COMPANY_ADMIN = BASE_URL + "/company.agents.delete";

export const GET_SETUP_DATA = BASE_URL + "/setup.data.info";
export const GET_COMPANY_ADMIN = BASE_URL + "/company.agents.info";
export const GET_PROJECT_INFO = BASE_URL + "/projects.info";
export const GET_PROJECT_VERSION = BASE_URL + "/project.versions.info";
export const GET_REVIEW_INFO = BASE_URL + "/reviews.info";
export const GET_TEST_ORDER_INFO = BASE_URL + "/order.tests.info";
export const GET_TESTER_TEST_INFO = BASE_URL + "/feature.tester.info";
export const GET_BILL_INFO = BASE_URL + "/bill.info";
export const GET_TESTER_ASSIGNED_FEATURES_IN_ORDER = BASE_URL + "/order.tests.features.get";
export const GET_COMPANY_DASHBOARD_DATA =BASE_URL + "/company.dashboard.stats.get";
export const GET_TESTER_DASHBOARD_DATA = BASE_URL + "/tester.dashboard.stats.get";
export const GET_AGENT_INVITATION_INFO = BASE_URL + "/company.agent.invitation.info";

export const LIST_COMPANY_AGENTS = BASE_URL + "/company.agents.list";
export const LIST_COMPANY_AGENTS_OF_COMPANY = BASE_URL + "/company.agents.for.company.list";
export const LIST_REVIEWS = BASE_URL + "/reviews.list";
export const LIST_PROJECT_TESTS_FOR_TESTER = BASE_URL + "/feature.tester.list";
export const LIST_COMPANY_AGENT_INVITATIONS = BASE_URL + "/company.agent.invitation.list";
export const LIST_COMPANY_BILLS = BASE_URL + "/bill.list";
export const LIST_AUDIT_LOGS = BASE_URL + "/audit.logs.list";

export const UPDATE_PROJECT_VERSION = BASE_URL + "/project.versions.update";
export const UPDATE_PROJECT_INFO = BASE_URL + "/projects.update";
export const UPDATE_REVIEW = BASE_URL + "/reviews.update";
export const UPDATE_COMPANY_AGENT_INVITATION = BASE_URL + "/company.agent.invitation.update";
export const UPDATE_TEST_ORDER_INFO = BASE_URL + "/order.tests.update";
export const UPDATE_USER_INFO = BASE_URL + "/users.update";
export const UPDATE_TESTER_TEST_INFO = BASE_URL + "/feature.tester.update";
export const UPDATE_TEST_FEATURE = BASE_URL + "/test.feature.update";
export const UPDATE_COMPANY_INFO = BASE_URL + "/companies.update";


export const SET_COMPANY_ID_TO_COOKIE = BASE_URL + "/auth.set.company"





export const GET_USER_INF0_URL = `${BASE_URL}/users.list`;

export const GET_COMPANY_INFO_URL = `${BASE_URL}/companies.get`;

export const CREATE_COMPANY_URL = `${BASE_URL}/companies.create`;

export const CREATE_USER_URL = `${BASE_URL}/users.create`;

export const CREATE_PROJECT_URL = `${BASE_URL}/projects.add`;
export const LIST_ALL_PROJECTS = `${BASE_URL}/projects.list`;

export const GET_COMPANY_PROJECTS_URL = `${BASE_URL}/company.agent.projects.list`;

export const UPDATE_USER_INF0_URL = `${BASE_URL}/users.update`;

export const UPDATE_COMPANY_INF0_URL = `${BASE_URL}/companies.update`;

export const GET_SINGLE_COMPANY_PROJECT_URL = `${BASE_URL}/projects/.get`;

export const GET_COMPANY_USERS_URL = `${BASE_URL}/companies.agents.list`;

export const GET_COMPANY_PROJECTS_FEATURES_URL = `${BASE_URL}/features.list`;

export const GET_COMPANY_PROJECTS_SINGLE_FEATURES_URL = `${BASE_URL}/features.get`;

export const CREATE_PROJECT_VERSION_URL = `${BASE_URL}/projects.versions.create`;


//Testing agent
export const GET_TESTING_AGENT_PROJECTS_URL = `${BASE_URL}/testing.agent.projects.list`
export const GET_TESTED_FEATURES_URL = `${BASE_URL}/tested.features.list`
export const SAVE_FEATURE_URL = `${BASE_URL}/features/.test.create`
// export const GET_TESTING_AGENT_SINGLE_PROJECT = `${BASE_URL}/`