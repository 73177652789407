import React, { useState } from "react";
import "./About.css";
import { ReactComponent as CancelIcon } from "../../assets/svgs/CancelIcon.svg";
import { ReactComponent as PlusIcon } from "../../assets/svgs/PlusIcon.svg";
import AboutImage from "../../assets/images/AboutImage.png";

function About() {
  const [tagged, setTagged] = useState("about");

  const removeTag = () => setTagged("");

  const tag = (event) =>
    setTagged(
      event.target.tagName === "svg"
        ? event.target.nextElementSibling.textContent
        : event.target.parentElement.nextElementSibling.textContent
    );

  return (
    <div className="mx-auto w-4/5 my-10">
      <div className="w-30">
        <h3 className="font-bold text-2xl">
          testable<span className="text-nice-blue">HQ</span>{" "}
        </h3>
        <h1 className="font-bold text-6xl mt-5 mb-16">Who we are</h1>
        <div className="flex">
          <div className="flex-1 ml-4">
            <div className="mb-5">
              {tagged === "about" ? (
                <CancelIcon
                  className="inline-block mr-3 cursor-pointer"
                  onClick={removeTag}
                />
              ) : (
                <PlusIcon
                  className="inline-block mr-3 cursor-pointer"
                  width="1rem"
                  onClick={tag}
                />
              )}{" "}
              <h3
                className={`inline-block ${
                  tagged === "about" ? "font-bold" : "font-semibold"
                }`}
              >
                about
              </h3>
              <div className={tagged === "about" ? "w-2/3 my-7" : "hidden"}>
                TestableHQ is a startup that is focused on providing software
                testing services to companies that require such services.
              </div>
            </div>
            <div className="mb-5">
              {tagged === "mission" ? (
                <CancelIcon
                  className="inline-block mr-3 cursor-pointer"
                  onClick={removeTag}
                />
              ) : (
                <PlusIcon
                  className="inline-block mr-3 cursor-pointer"
                  width="1rem"
                  onClick={tag}
                />
              )}{" "}
              <h3
                className={`inline-block ${
                  tagged === "mission" ? "font-bold" : "font-semibold"
                }`}
              >
                mission
              </h3>
              <div className={tagged === "mission" ? "w-2/3 my-7" : "hidden"}>
                Our mission, here at TestableHQ, is to create jobs for hundreds
                of people in Africa and other parts of the world in the area of
                software testing.
              </div>
            </div>
            <div className="mb-5">
              {tagged === "vision" ? (
                <CancelIcon
                  className="inline-block mr-3 cursor-pointer"
                  onClick={removeTag}
                />
              ) : (
                <PlusIcon
                  className="inline-block mr-3 cursor-pointer"
                  width="1rem"
                  onClick={tag}
                />
              )}{" "}
              <h3
                className={`inline-block ${
                  tagged === "vision" ? "font-bold" : "font-semibold"
                }`}
              >
                vision
              </h3>
              <div className={tagged === "vision" ? "w-2/3 my-7" : "hidden"}>
                TestableHQ's vision is to be the go-to platform for software
                testing, for companies without a dedicated software team and
                freelancers who want to outsource software testing.
              </div>
            </div>
          </div>
          <div className="flex-0">
            <img src={AboutImage} alt="" width={"80%"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
