import React, { useState } from "react";
import { NoItems, Table } from "@kehillahglobal/ui";
import { PlusIcon } from "@heroicons/react/outline";
import PopModal from "../../../SharedComponents/popModal/PopModal";
import { useDispatch, useSelector } from "react-redux";
import TableActions from "../../../SharedComponents/actions/TableActions";
import { getQuote } from "../../../utils/Quotes";
import { humanReadableDate, isEmpty } from "../../../utils/utils";
import CompanyAdminInvitationForm from "./CompanyAdminInvitationForm";
import ConfirmationModal from "../../../SharedComponents/popModal/ConfirmationModal";
import { DELETE_COMPANY_AGENT_INVITATION } from "../../../utils/urls";
import { updateAdminInvitationsLocallyAction } from "../../../store/actions";
import { formApiCall } from "../../../utils/api";
import { toast } from "react-toastify";
import ProjectStatus from "../../../SharedComponents/ProjectInfo/components/ProjectStatus";
import { OWNER } from "../../../utils/constant";


export default function InvitedAdmins() {
  const invitations = useSelector((state) => state.invitations);
  const loggedInAdmin = useSelector((state) => state.admin);
  const dispatch = useDispatch()
  const [showAddModal, setShowAddModal] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [activeID, setActiveID] = useState("");
  const [selectedInvitation, setSelectedInvitation] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      setActiveID("");
      setShowActions(false);
    } else {
      setActiveID(item?.id);
      setShowActions(true);
    }
  };
  const onDeleteVersion = () => {
    formApiCall(DELETE_COMPANY_AGENT_INVITATION, {
      invitation_id: selectedInvitation?.id,
    }).then((res) => {
      setSelectedInvitation({});
      if (res?.success) {
        toast.success("Admin invitation successfully deleted");
        let filtered = invitations?.filter(
          (item) => item?.id !== selectedInvitation?.id
        );
        dispatch(updateAdminInvitationsLocallyAction(filtered))
      } else {
        toast.error(res?.error);
      }
    });
  };

  const prepareTableData = () => {
    return invitations.map((admin, index) => [
      <p>{index + 1}</p>,
      <p>{humanReadableDate(admin?.created_at)}</p>,
      <p>{admin?.full_name}</p>,
      <p>{admin?.code}</p>,
      <p>{admin?.email}</p>,
      <ProjectStatus status={admin?.status} />,
      loggedInAdmin?.role === OWNER ? (
        <TableActions
          onDelete={() => {
            setSelectedInvitation(admin);
            setShowDeleteConfirmation(true);
          }}
          onUpdate={() => {
            setShowAddModal(true);
            setSelectedInvitation(admin);
          }}
          item={admin}
          active={activeID}
          toggleShowMoreOptions={toggleShowMoreOptions}
          show={showActions}
        />
      ) : (
        <TableActions
          onUpdate={() => {
            setShowAddModal(true);
            setSelectedInvitation(admin);
          }}
          item={admin}
          active={activeID}
          toggleShowMoreOptions={toggleShowMoreOptions}
          show={showActions}
        />
      ),
    ]);
  };
  return (
    <>
      <div>
        <div
          className="add-item-container"
          onClick={() => {
            setSelectedInvitation({});
            setShowAddModal(true);
          }}
        >
          <PlusIcon className="h-6 w-7" />
          <p>Invite An Admin</p>
        </div>
        {isEmpty(invitations) ? (
          <NoItems text={getQuote()} />
        ) : (
          <Table
            columns={[
              "#",
              "Created At",
              "Name",
              "Code",
              "Email",
              "Status",
              "Actions",
            ]}
            data={prepareTableData()}
            hoverAnimation={false}
          />
        )}
        {showAddModal && (
          <PopModal
            close={() => setShowAddModal(false)}
            title="Company Agent Invitation Form"
            size={"medium"}
            children={
              <CompanyAdminInvitationForm
                close={() => setShowAddModal(false)}
                toggleShowMoreOptions={toggleShowMoreOptions}
                toEdit={selectedInvitation}
              />
            }
          />
        )}
        {showDeleteConfirmation && (
          <ConfirmationModal
            close={() => setShowDeleteConfirmation(false)}
            msg={`Are you sure you want to delete this invitation ? `}
            onDelete={() => onDeleteVersion()}
          />
        )}
      </div>
    </>
  );
}
