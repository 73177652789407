import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActivityIndicator from "../../SharedComponents/Loader.js/ActivityIndicator";
import { setPrefixAction } from "../../store/actions";
import { formApiCall } from "../../utils/api";
import { COMPANY_PREFIX, LOGIN_AS_COMPANY_ADMIN } from "../../utils/constant";
import {
  LIST_COMPANY_AGENTS,
  SET_COMPANY_ID_TO_COOKIE,
} from "../../utils/urls";
import { isEmpty } from "../../utils/utils";

export default function CompanySelection() {
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [userCompanies, setUserCompanies] = useState([]);

  const dummyCompanyImage =
    "https://static3.eyellowpages.ph/assets/placeholder-company-30f7247c043cac59226403f669f2afea709784b1f52cc405c90672c3d9ec428a.jpg";

  useEffect(() => {
    formApiCall(LIST_COMPANY_AGENTS, {}).then((res) => {
      if (res?.success) {
        const companies = res?.data;
        if (isEmpty(companies) || companies?.length === 1) {
          setCompany(companies[0].company?.id, companies[0]?.id);
          dispatch(setPrefixAction(COMPANY_PREFIX));
          localStorage.removeItem(LOGIN_AS_COMPANY_ADMIN);
          localStorage.setItem("prefix", COMPANY_PREFIX);
          return (window.location.href = "/" + COMPANY_PREFIX + "/dashboard");
        }
        setUserCompanies(companies);
      }
    });
    // eslint-disable-next-line
  }, [user?.id]);

  const dispatch = useDispatch();

  const setCompany = (company_id, company_admin_id) => {
    setIsLoading(true);
    formApiCall(SET_COMPANY_ID_TO_COOKIE, {
      company_id,
      company_admin_id,
    }).then((res) => setIsLoading(false));
  };
  return userCompanies?.length < 1 ? (
    <ActivityIndicator text={"Preparing For You 🤗"} />
  ) : isLoading ? (
    <ActivityIndicator text={"Preparing For You 🤗"} />
  ) : (
    <div className="success-accounts-wrapper">
      <div className="success-accounts-root">
        <div>
          <h1
            className="text-8xl tracking-tighter mb-2"
            style={{ textAlign: "center" }}
          >
            Welcome Back
          </h1>
          <p style={{ textAlign: "center" }}>
            Join the world's largest software testing community
          </p>
        </div>

        <div className="mt-10">
          <p className="font-bold text-2xl tracking-tighter mb-2">Profiles</p>
          <div className="grid-repeat-3">
            {userCompanies?.map((company, index) => {
              return (
                <div
                  className="p-5 elevate-float account-card"
                  onClick={() => {
                    setCompany(company?.company?.id, company?.id);
                    dispatch(setPrefixAction(COMPANY_PREFIX));
                    localStorage.removeItem(LOGIN_AS_COMPANY_ADMIN);
                    localStorage.setItem("prefix", COMPANY_PREFIX);
                    return (window.location.href =
                      "/" + COMPANY_PREFIX + "/dashboard");
                  }}
                >
                  <div>
                    <img
                      className="success-account-img"
                      src={company?.company?.logo || dummyCompanyImage}
                      alt="preview"
                    />
                  </div>
                  <center className="mt-2">
                    <span className="tracking-tighter">{company?.role}</span>
                    <p className="tracking-tighter font-bold mt-3">
                      {company?.company?.name}
                    </p>
                  </center>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
