import React from "react";
import Header from "../../SharedComponents/Header/Header";

export default function Error() {
  return (
    <div className="error-container center-me">
      <div>
        <h1 className="no-icon">🤭</h1>
      </div>
      <div className="text-container">
        <Header text={"Oops page not found"} size={2} />
      </div>
    </div>
  );
}
