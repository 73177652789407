import React from "react";

export default function ZipIcon({ size }) {
  return (
    <div >
      <xml version="1.0" encoding="iso-8859-1" />
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style={{width:`${size}%`}}
      >
        <path
          style={{ fill: "#FDCC77" }}
          d="M511.344,274.266C511.77,268.231,512,262.143,512,256C512,114.615,397.385,0,256,0S0,114.615,0,256
	c0,117.769,79.53,216.949,187.809,246.801L511.344,274.266z"
        />
        <path
          style={{ fill: "#FCB433" }}
          d="M511.344,274.266L314.991,77.913L119.096,434.087l68.714,68.714C209.522,508.787,232.385,512,256,512
	C391.243,512,501.976,407.125,511.344,274.266z"
        />
        <polygon
          style={{ fill: "#FFFFFF" }}
          points="278.328,333.913 255.711,77.913 119.096,77.913 119.096,311.652 "
        />
        <polygon
          style={{ fill: "#E8E6E6" }}
          points="392.904,311.652 392.904,155.826 337.252,133.565 314.991,77.913 255.711,77.913 
	256.067,333.913 "
        />
        <polygon
          style={{ fill: "#FFFFFF" }}
          points="314.991,155.826 314.991,77.913 392.904,155.826 "
        />
        <rect
          x="119.096"
          y="311.652"
          style={{ fill: "#9E7120" }}
          width="273.809"
          height="122.435"
        />
        <g>
          <path
            style={{ fill: "#FFFFFF" }}
            d="M210.927,388.691h28.759v10.671h-46.771v-8.627l28.38-33.677H193.9v-10.671h45.332v8.627
		L210.927,388.691z"
          />
          <path
            style={{ fill: "#FFFFFF" }}
            d="M249.075,399.362v-52.975h13.471v52.975H249.075z"
          />
          <path
            style={{ fill: "#FFFFFF" }}
            d="M298.118,346.387c13.546,0,21.341,6.659,21.341,18.465c0,12.412-7.796,19.601-21.341,19.601h-9.612
		v14.909h-13.471v-52.975L298.118,346.387L298.118,346.387z M288.505,373.858h8.93c5.904,0,9.307-2.952,9.307-8.552
		c0-5.525-3.405-8.324-9.307-8.324h-8.93V373.858z"
          />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    </div>
  );
}
