import { NoItems, Table } from "@kehillahglobal/ui";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TableActions from "../../../../../SharedComponents/actions/TableActions";
import ConfirmationModal from "../../../../../SharedComponents/popModal/ConfirmationModal";
import PopModal from "../../../../../SharedComponents/popModal/PopModal";
import ProjectStatus from "../../../../../SharedComponents/ProjectInfo/components/ProjectStatus";
import { formApiCall } from "../../../../../utils/api";
import { getQuote } from "../../../../../utils/Quotes";
import { DELETE_TEST_ORDER_INFO, UPDATE_TEST_ORDER_INFO } from "../../../../../utils/urls";
import { humanReadableDate, isEmpty } from "../../../../../utils/utils";
import TestRequestForm from "../TestRequestForm";

export default function ProjectTests({ setProject, project }) {
  const [showActions, setShowActions] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [activeID, setActiveID] = useState("");
  const [selectedTest, setSelectedTest] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);


  const prefix = useSelector((state) => state.prefix);

  const navigate = useNavigate();

  const toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      setActiveID("");
      setShowActions(false);
    } else {
      setActiveID(item?.id);
      setShowActions(true);
    }
  };

  const onDeleteVersion = () => {
    formApiCall(DELETE_TEST_ORDER_INFO, {
      order_test_id: selectedTest?.id,
    }).then((res) => {
      setSelectedTest({});
      if (res?.success) {
        toast.success("Test request successfully deleted");
        let { tests } = project;
        let filtered = tests?.filter((item) => item?.id !== selectedTest?.id);
        project.tests = filtered;
        setProject(project);
      } else {
        toast.error(res?.error);
      }
    });
  };

  const handleUpdateTestOrder = (data, reset) => {
  let toSend = {
    id: selectedTest?.id,
    ...data,
    testing_sheet: data.testing_sheet?.data?.file?.data,
    file: data.file?.data?.file?.data,
  };
  if (!toSend?.file) {
    delete toSend.file;
  }
  if (!toSend?.testing_sheet) {
    delete toSend.testing_sheet;
  }
  delete toSend?.number

  formApiCall(UPDATE_TEST_ORDER_INFO, toSend).then((res) => {
    if (res?.success) {
      let { tests } = project;
      let filtered = tests?.filter(item=> item?.id !== res?.data?.id)
      project.tests = [res?.data, ...filtered];
      setProject(project);
      setShowAddModal(false);
      toggleShowMoreOptions()
      toast.success("Test request successful updated !");
    } else {
      toast.error(res?.error);
    }
  });
  };

  const showSource = (version) => {
    if (version?.file) {
      return "Zip";
    }
    return "URL";
  };
  const prepareTableData = () => {
    return project?.tests?.map((test, index) => [
      <p>{index + 1}</p>,
      <p onClick={() =>navigate(`/${prefix}/projects/${project?.id}/tests/` + test?.id) }>
        v{test?.version?.number}
      </p>,
      <p>{humanReadableDate(test?.created_at)}</p>,
      <p>{showSource(test)}</p>,
      <p>{test?.number_of_features}</p>,
      <ProjectStatus status={test?.status} />,
      <TableActions
        onDelete={() => {
          setSelectedTest(test);
          setShowDeleteConfirmation(true);
        }}
        onUpdate={(item) => {
          setSelectedTest(test);
          setShowAddModal(true);
        }}
        onView={() =>
          navigate(`/${prefix}/projects/${project?.id}/tests/` + test?.id)
        }
        item={test}
        active={activeID}
        toggleShowMoreOptions={toggleShowMoreOptions}
        show={showActions}
      />,
    ]);
  };
  return (
    <div>
      {isEmpty(project?.tests) ? (
        <NoItems text={getQuote()} />
      ) : (
        <Table
          columns={[
            "#",
            "Version",
            "Submitted At",
            "Source",
            "No.of Features",
            "Status",
            "Actions",
          ]}
          data={prepareTableData()}
          hoverAnimation={false}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmationModal
          close={() => setShowDeleteConfirmation(false)}
          msg={`You're about to delete test of version v${selectedTest?.version?.number}. This operation can not be undone.`}
          onDelete={() => onDeleteVersion()}
        />
      )}
      {showAddModal && (
        <PopModal
          close={() => setShowAddModal(false)}
          title="Update Test Request Information Form"
          size={"medium"}
          children={
            <TestRequestForm
              handleCreateTestOrder={handleUpdateTestOrder}
              toEdit={selectedTest}
              versions={project?.versions}
            />
          }
        />
      )}
    </div>
  );
}
