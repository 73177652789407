import React from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";

function Button({ name, fill = false, onClick, style }) {
  const [fillState, setFllState] = React.useState(false);
  return (
    <>
      <button
        style={style}
        className={`rounded-full flex flex-row items-center justify-between cursor-pointer p-3 md:p-4 lg:p-4 lg:w-max-content scale-100 hover:scale-105 transition duration-300 ease-in-out
       ${
        fill
        ? "bg-fuchsia-900 text-white hover:bg-fuchsia-800"
        : "bg-white border-solid text-black border-2 border-fuchsia-900 hover:bg-fuchsia-900 hover:text-white"
    }`}
        onClick={(e) => {
          onClick(e);
        }}
        onMouseEnter={() => setFllState(true)}
        onMouseLeave={() => setFllState(false)}
      >
        <div className="tracking-tighter text-md font-bold">{name}</div>
        <div>
          <ChevronRightIcon
            className={`h-5 w-5 ${!fill && fillState ? "text-white" : ""}`}
            color={`${fill ? "white" : "black"}`}
          />
        </div>
      </button>
    </>
  );
}

export default Button;
