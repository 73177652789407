import { PencilIcon } from "@heroicons/react/solid";
import { Dropdown, Textbox } from "@kehillahglobal/ui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "../../../SharedComponents";
import Loader from "../../../SharedComponents/Loader.js/Loader";
import { saveCompanyAdminInfoAction } from "../../../store/actions";
import { formApiCall } from "../../../utils/api";
import { placeholderImage } from "../../../utils/constant";
import { UPDATE_COMPANY_INFO } from "../../../utils/urls";
import { fileUploader } from "../../../utils/utils";

export default function CompanySettings() {
  let [loading, setLoading] = useState(false);
  const { company, role, roles } = useSelector((state) => state.admin);

  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [industry, setIndustry] = useState("");
  const [logo, setLogo] = useState("");

  useEffect(() => {
    setName(company?.name);
    setEmail(company?.email||"");
    setIndustry(company?.industry);
    setCountry(company?.country);
    setLogo(company?.logo?.file);
  }, [company]);

  const handleSelectProfile = () => {
    let fileInput = document.getElementById("company-image-input");
    fileInput.click();

    fileInput.onchange = async (e) => {
      setLoading(true);
      let logo = await fileUploader(e.target.files[0]);
      if (logo?.id) {
        formApiCall(UPDATE_COMPANY_INFO, {
          id: company?.id,
          logo_id: logo?.id,
        }).then((res) => {
          setLoading(false);
          if (res?.success) {
            dispatch(
              saveCompanyAdminInfoAction({
                company: res?.data,
                role: role,
                roles,
              })
            );
            setLogo(res?.data?.logo?.file);
            toast.success("profile image updated successfully");
          } else {
            toast.error("An error occurred. Please try again");
          }
        });
      } else {
        setLoading(false);
        toast.error("An error occurred. Please try again");
      }
    };
  };

  const handleUpdateCompanyInfo = () => {
    setLoading(true);
    let data = {
      name,
      email,
      country,
      industry,
    };

    formApiCall(UPDATE_COMPANY_INFO, {
      ...data,
      id: company?.id,
    }).then((res) => {
      setLoading(false);
      if (res?.success) {
        dispatch(
          saveCompanyAdminInfoAction({
            company: res?.data,
            role: role,
            roles,
          })
        );
        toast.success("Company information updated successfully");
      } else {
        toast.error("An error occurred. Please try again.");
      }
    });
  };

  const renderEditForm = () => {
    return (
      <div className="mt-10">
        <div className="grid-repeat-2 mb-5">
          <div>
            <label class="block text-gray-700 text-sm font-bold" for="username">
              Company Name
            </label>
            <Textbox value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div>
            <label class="block text-gray-700 text-sm font-bold" for="username">
              Contact Email
            </label>
            <Textbox value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
        </div>
        <div className="mb-5">
          <label class="block text-gray-700 text-sm font-bold" for="username">
            Country
          </label>
          <Dropdown
            data={["Ghana", "Canada", "USA"]}
            placeholder={"Select Country"}
            value={country}
            onItemSelected={(current, x, y) => setCountry(current)}
          />
        </div>

        <div>
          <label class="block text-gray-700 text-sm font-bold" for="username">
            Industry
          </label>
          <Dropdown
            data={["Agriculture", "Technology", "Banking"]}
            placeholder={"Select company's industry"}
            value={industry}
            onItemSelected={(current, x, y) => setIndustry(current)}
          />
        </div>
        <div className="mt-10 flex-right">
          <Button
            name={"Save Changes"}
            fill={true}
            onClick={() => handleUpdateCompanyInfo()}
          />
        </div>
      </div>
    );
  };
  return (
    <>
      <div>
        <div className="profile-wrapper">
          <div className="profile-image-container">
            <input
              type={"file"}
              id="company-image-input"
              className="hide"
              accept="image/*"
            />
            <img src={logo || placeholderImage} alt="  " />
            <div
              className="change-profile-container"
              onClick={() => handleSelectProfile()}
            >
              <PencilIcon className="h-6 w-7" />
            </div>
          </div>
          <div className="ml-5">
            <h3 className="font-bold text-3xl tracking-tighter">
              {company?.name}
            </h3>
            <p className="tracking-tighter mb-3">
              {company?.industry}
            </p>
            <span>{company?.email}</span>
          </div>
        </div>
        {renderEditForm()}
      </div>

      {loading && <Loader text={"processing.."} />}
    </>
  );
}
