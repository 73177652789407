import { PlusIcon } from '@heroicons/react/outline';
import React from 'react'

export default function AddButton({text, onClick}) {
  return (
    <div
      className="add-item-container"
      onClick={() => {
        onClick && onClick()
      }}
    >
      <PlusIcon className="h-6 w-7" />
      <p>{text}</p>
    </div>
  );
}
