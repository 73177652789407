import { Modal, NoItems, Table } from "@kehillahglobal/ui";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TableActions from "../../../SharedComponents/actions/TableActions";
import AddButton from "../../../SharedComponents/addButton";
import ConfirmationModal from "../../../SharedComponents/popModal/ConfirmationModal";
import { updateProjectListLocallyAction } from "../../../store/actions";
import { formApiCall } from "../../../utils/api";
import { getQuote } from "../../../utils/Quotes";
import { DELETE_PROJECT } from "../../../utils/urls";
import { isEmpty } from "../../../utils/utils";
import CreateAndUpdateProject from "./create-project/CreateAndUpdateProject";
import "./index.css";

export default function Projects() {
  const [renderCreate, setRenderCreate] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [activeID, setActiveID] = useState("");
  const [project, setProject] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects);
  const prefix = useSelector((state) => state.prefix);

  const goToDetails = (id) => {
    navigate(`/${prefix}/projects/` + id?.toString());
  };

  const toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      setActiveID("");
      setShowActions(false);
    } else {
      setActiveID(item?.id);
      setShowActions(true);
    }
  };

  const onDeleteVersion = () => {
    formApiCall(DELETE_PROJECT, {
      project_id: project?.id,
    }).then((res) => {
      setProject({});
      if (res?.success) {
        toast.success("Project successfully deleted");
        let filtered = projects.filter((item) => item?.id !== project?.id);
        dispatch(updateProjectListLocallyAction(filtered));
      } else {
        toast.error(res?.error);
      }
    });
  };

  const prepareTableData = () => {
    return projects.map((pro, index) => [
      <p>{index + 1}</p>,
      <p onClick={() => goToDetails(pro?.id)}>{pro?.name}</p>,
      <p>{pro?.stats?.number_of_versions}</p>,
      <p>{pro?.stats?.total_tests}</p>,
      <p>{pro?.stats?.tests_completed}</p>,
      <TableActions
        onDelete={() => {
          setProject(pro);
          setShowDeleteConfirmation(true);
        }}
        onUpdate={() => {
          setRenderCreate(true);
          setProject(pro);
        }}
        onView={() => goToDetails(pro?.id)}
        item={pro}
        active={activeID}
        toggleShowMoreOptions={toggleShowMoreOptions}
        show={showActions}
      />,
    ]);
  };
  return (
    <div>
      <div>
        <h1 className="font-bold text-4xl tracking-tighter mb-10">Projects</h1>
      </div>
      <div>
        <AddButton
          text={"New Project"}
          onClick={() => {
            setRenderCreate(true);
            setProject({});
          }}
        />
        {isEmpty(projects) ? (
          <NoItems text={getQuote()} />
        ) : (
          <Table
            columns={[
              "#",
              "Name",
              "Versions",
              "Tests Created",
              "Tests Completed",
              "Actions",
            ]}
            data={prepareTableData()}
            hoverAnimation={false}
          />
        )}
      </div>
      {renderCreate && (
        <Modal
          close={() => setRenderCreate(false)}
          title="Project Creation Form"
          size="medium"
        >
          <CreateAndUpdateProject
            close={() => {
              setRenderCreate(false);
              setProject({});
              toggleShowMoreOptions();
            }}
            toEdit={project}
          />
        </Modal>
      )}

      {showDeleteConfirmation && (
        <ConfirmationModal
          close={() => setShowDeleteConfirmation(false)}
          msg={`You're about to delete the project ${project?.name}. This operation will delete all versions, tests and reviews associated with this project.`}
          onDelete={() => onDeleteVersion()}
        />
      )}
    </div>
  );
}
