import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../../SharedComponents/Header/Header";
import { Button } from "../../../SharedComponents/index";
import ActivityIndicator from "../../../SharedComponents/Loader.js/ActivityIndicator";
import { saveUserInfoAction } from "../../../store/actions";
import { formApiCall } from "../../../utils/api";
import { DECLINED, dummyCompanyImage } from "../../../utils/constant";
import {
  ACCEPT_COMPANY_INVITATION,
  AUTH_URL,
  GET_AGENT_INVITATION_INFO,
  LOGOUT,
  UPDATE_COMPANY_AGENT_INVITATION,
} from "../../../utils/urls";

import "./CompanyInvitation.css";

function CompanyInvitation() {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { code } = useParams();
  const [invitation, setInvitation] = useState([]);
  const [error, setError] = useState("");
  useEffect(() => {
    if (user?.id) {
      formApiCall(GET_AGENT_INVITATION_INFO, {
        invitation_code: code,
      }).then((res) => {
        if (res?.success) {
          setInvitation(res?.data);
        } else {
          setError(res?.error);
        }
      });
    } else {
      localStorage.lastVisited = window.location.pathname;
      return (window.location.href = AUTH_URL);
    }
  }, [user?.id, code]);

  //eslint-disable-next-line
  const [activity, setActivity] = useState(false);

  const onAccept = () => {
    localStorage.clear();
    setActivity(true);
    formApiCall(ACCEPT_COMPANY_INVITATION, {
      id: invitation?.id,
    }).then((res) => {
      setActivity(false);
      if (res?.success) {
        dispatch(saveUserInfoAction(res?.data));
        navigate("/company/account/selection");
      } else {
        toast.error("An error occurred. Please Try again");
      }
    });
  };
  const onDecline = () => {
    localStorage.clear();
    formApiCall(UPDATE_COMPANY_AGENT_INVITATION, {
      id: invitation?.id,
      status: DECLINED,
    }).then((res) => {
      formApiCall(LOGOUT, {}).then((res) => {
        if (res.success) {
          window.location.href = "/";
        }
      });
    });
  };

  return user?.id ? (
    <>
      {error ? (
        <div className="flex flex-col items-center justify-center w-screen h-screen">
          <div className="elevate-float py-4 px-3 invitation-not-found-container">
            <center>
              <Header size={2} text={"🤭 Not Found"} />
            </center>
            <div>
              <p>
                This invitation code has either been deleted or has expired.
                Click <Link to={"/"}>here</Link> to view our homepage
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center w-screen h-screen space-y-8">
          <div className="font-extrabold text-2xl">
            {invitation?.company?.name}
          </div>

          <div>
            <img
              className="w-46 h-36 rounded-lg transition duration-300 ease-in-out"
              src={invitation?.company?.logo?.file || dummyCompanyImage}
              alt="preview"
            />
          </div>

          <div className="">
            <div className="font-bold text-md text-center">
              {user?.full_name}
            </div>
            <div className="text-sm text-center text-gray-400">
              {user?.email}
            </div>
          </div>

          <div>
            <div>
              You have been invited by {invitation?.sender?.full_name} to join{" "}
              <span className="font-bold">{invitation?.company?.name}</span>
            </div>
          </div>

          {activity ? (
            <div className="animate-pulse text-yellow-600 font-bold text-md">
              processing ...
            </div>
          ) : (
            <div className="flex flex-row items-center space-x-4 pt-5 flex-wrap">
              <Button name={"Accept"} fill={true} onClick={(e) => onAccept()} />
              <Button name={"Decline"} onClick={() => onDecline()} />
            </div>
          )}
        </div>
      )}
    </>
  ) : (
    <ActivityIndicator />
  );
}

export default CompanyInvitation;
