import { ChevronRightIcon } from "@heroicons/react/solid";
import React from "react";
import { Link } from "react-router-dom";

function BreadCrumbs({ routes }) {
  const Crumb = ({ id, name, redirect }) => {
    if (id === routes[routes.length - 1]["id"]) {
      return (
        <>
          <div className="flex flex-row items-center ">
            <div className="text-sm font-bold">{name}</div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="flex flex-row items-center space-x-2">
            <Link to={redirect} style={{textDecoration:'none'}}>
              <div className="text-sm font-semibold text-gray-400 hover:text-blue-400 cursor-pointer">
                {name}
              </div>
            </Link>
            <ChevronRightIcon className="text-gray-400 h-5 w-5" />
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="flex flex-row space-x-2 md:px-1 px-10 py-5">
        {routes &&
          routes.map((route) => {
            return (
              <Crumb
                key={route.id}
                id={route.id}
                name={route.name}
                redirect={route.redirect}
              />
            );
          })}
      </div>
    </>
  );
}

export default BreadCrumbs;
