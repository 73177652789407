import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-off-white pt-10 pb-3 lg:pb:6 lg:pt-20 lg:text-left text-center">
      <div className="flex flex-col align-middle justify-center w-fit lg:space-x-20 space-y-10 lg:flex-row mx-auto">
        <div className="flex flex-col space-y-10">
          <h1 className="font-extrabold lg:pt-8 self-center lg:self-start text-2xl lg:text-5xl w-fit">
            Testable<span className="text-nice-blue">HQ</span>
          </h1>
          <p className="w-60 lg:w-80">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut consequat.
          </p>
        </div>
        <div className="flex flex-col space-y-5 self-center lg:self-start">
          <h6 className="font-bold text-2xl self-center lg:self-start w-fit">
            Services
          </h6>
          <div className="w-fit space-y-2 font-semibold text-deep-ash">
            <p>mobile app testing</p>
            <p>web app testing</p>
            <p>desktop app testing</p>
          </div>
        </div>
        <div className="flex flex-col self-center lg:self-start space-y-5">
          <h6 className="font-bold text-2xl self-center lg:self-start w-fit">
            Links
          </h6>
          <div className="w-fit font-semibold  text-deep-ash">
            <Link to={"/about"} className="block hover:text-nice-blue">
              about us
            </Link>
            <Link to={"/contact"} className="block hover:text-nice-blue">
              contact us
            </Link>
            <Link to={"/pricing"} className="block hover:text-nice-blue">
              pricing
            </Link>
            <Link to={"/services"} className="block hover:text-nice-blue">
              services
            </Link>
          </div>
        </div>
      </div>
      <div className="mx-auto mt-10 lg:mt-12 w-fit font-semibold text-sm text-deep-ash">
        copyright@2020 | TestableHQ
      </div>
    </div>
  );
};

export default Footer;
