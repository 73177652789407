import { DownloadIcon } from "@heroicons/react/outline";
import { FormGenerator, NoItems, Table } from "@kehillahglobal/ui";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadCrumbs } from "../../../SharedComponents";
import Header from "../../../SharedComponents/Header/Header";
import Loader from "../../../SharedComponents/Loader.js/Loader";
import PopModal from "../../../SharedComponents/popModal/PopModal";
import ProjectStatus from "../../../SharedComponents/ProjectInfo/components/ProjectStatus";
import { formApiCall } from "../../../utils/api";
import { getQuote } from "../../../utils/Quotes";
import {
  CREATE_FEATURE_TEST_MEDIA,
  GET_TESTER_ASSIGNED_FEATURES_IN_ORDER,
  UPDATE_TESTER_TEST_INFO,
} from "../../../utils/urls";
import {
  fileUploader,
  goTo,
  humanReadableDate,
  isEmpty,
} from "../../../utils/utils";

export default function TesterTestDetails() {
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [featureToUpdate, setFeatureToUpdate] = useState({});
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(false);

  const { orderID } = useParams();

  const [testOrder, setOrder] = useState([]);

  useEffect(() => {
    setLoading(true)
    formApiCall(GET_TESTER_ASSIGNED_FEATURES_IN_ORDER, {
      order_id: orderID,
    }).then((res) => {
          setLoading(false);
      if (res?.success) {
        setOrder(res?.data);
      }
    });
  }, [orderID]);

  const prepareTableData = () => {
    return testOrder?.tests?.map((test, index) => [
      <p>{index + 1}</p>,
      <p>{humanReadableDate(test?.created_at)}</p>,
      <p> {test?.feature}</p>,
      <p onClick={() => test?.media?.length > 0 && setMedia(test?.media)}>
        {" "}
        {test?.media?.length || 0}
      </p>,
      <ProjectStatus status={test?.status || "Pending"} />,
      <div
        className="view-more-btn"
        onClick={() => {
          setShowUpdateForm(true);
          setFeatureToUpdate(test);
        }}
      >
        <p>Update Status</p>
      </div>,
    ]);
  };

  const breadCrumb = [
    {
      id: 1,
      name: "tests",
      redirect: `/tester/tests/`,
    },
    {
      id: 2,
      name: `${testOrder?.company}`,
      redirect: `/tester/tests/${orderID}`,
    },
  ];
  const getSourceFile = () => {
    return isEmpty(testOrder?.file) ? testOrder?.url : testOrder?.file;
  };

  return  (
    <div>
      <BreadCrumbs routes={breadCrumb} />

      <div className="testOrder-test-and-header-container">
        <Header
          text={`${testOrder?.project} v${testOrder?.version}`}
          size={3}
        />
      </div>

      <div className="test-detail-gen-info-root mt-2">
        <div>
          <span className="mr-2">Date Assigned: </span>
          <span>{humanReadableDate(testOrder?.created_at)}</span>
        </div>
        <div>
          <span className="mr-2">Deadline: </span>
          <span>{humanReadableDate(testOrder?.deadline)}</span>
        </div>
        <div>
          <span className="mr-2">Status: </span>
          <ProjectStatus status={testOrder?.status || "Pending"} />
        </div>
        {testOrder?.file ? (
          <div>
            <span className="mr-2">Download software:</span>
            <DownloadIcon
              className="h-6 w-7 pointer"
              onClick={() => goTo(getSourceFile())}
            />
          </div>
        ) : (
          <div>
            <span className="mr-2">Visit Platform:</span>
            <span className="link" onClick={() => goTo(getSourceFile())}>
              URL
            </span>
          </div>
        )}
      </div>
      <div className="mt-10">
        <Header text={`Test Instructions`} size={2} />
        <div
          className="justify rich-text"
          dangerouslySetInnerHTML={{
            __html: testOrder?.instructions || `No Instructions Added`,
          }}
        ></div>
      </div>

      <div>
        <Header text={`Assigned Tests`} size={2} />
        {isEmpty(testOrder?.tests) ? (
          <NoItems text={getQuote()} />
        ) : (
          <Table
            columns={[
              "#",
              "Assigned At",
              "Feature",
              "Media uploads",
              "Status",
              "Action",
            ]}
            data={prepareTableData()}
            hoverAnimation={false}
          />
        )}
      </div>

      {showUpdateForm && (
        <PopModal
          close={() => setShowUpdateForm(false)}
          title="Update Testing Status"
          size={"medium"}
          children={
            <UpdateTestingStatusForm
              test={featureToUpdate}
              setOrder={setOrder}
              testOrder={testOrder}
              close={() => setShowUpdateForm(false)}
              setLoading={setLoading}
            />
          }
        />
      )}
      {media?.length>0 && (
        <PopModal
          close={() => setMedia([])}
          title=" "
          size={"medium"}
          children={<RenderShots media={media} />}
        />
      )}

      {loading && <Loader text={"Processing ..."} />}
    </div>
  );
}

function UpdateTestingStatusForm({ test, setOrder, close, testOrder,  setLoading}) {
  return (
    <FormGenerator
      title={""}
      subtitle={""}
      onSubmit={(data, reset) => {
        let toSend = {
          id: test?.id,
          status: data?.status,
          note: data?.note,
        };
        setLoading(true)
        if (data?.media?.data.length > 0) {
          data?.media?.data?.forEach(async (shot, index, arr) => {
            let file = await fileUploader(shot?.file?.data);
            if (file?.id) {
              formApiCall(CREATE_FEATURE_TEST_MEDIA, {
                media_id: file?.id,
                feature_test_id: test?.id,
              }).then((res) => {
                if (res?.success && index === arr?.length - 1) {
                  formApiCall(UPDATE_TESTER_TEST_INFO, toSend).then((res) => {
                    setLoading(false)
                    if (res?.success) {
                      test.status = res?.data?.status;
                      test.note = res?.data?.note;
                      test.media = res?.data.media;
                      let removed = testOrder?.tests?.filter(
                        (item) => item?.id !== test?.id
                      );
                      let newItems = [test, ...removed];
                      testOrder.tests = newItems;
                      setOrder(testOrder);
                      close();
                      toast.success("Testing status updated successfully.");
                    } else {
                      toast.error("An error occurred. Please try again");
                    }
                  });
                }
              });
            }
          });
        } else {
          formApiCall(UPDATE_TESTER_TEST_INFO, toSend).then((res) => {
            setLoading(false)
            if (res?.success) {
              test.status = res?.data?.status;
              test.note = res?.data?.note;
              test.media = res?.data.media;
              let removed = testOrder?.tests?.filter(
                (item) => item?.id !== test?.id
              );
              let newItems = [test, ...removed];
              testOrder.tests = newItems;
              setOrder(testOrder);
              close();
              toast.success("Testing status updated successfully.");
            } else {
              toast.error("An error occurred. Please try again");
            }
          });
        }
      }}
      formSubmitBtnText={"Submit"}
      elevation={0}
      fields={[
        {
          fieldType: FormGenerator.Fields.DROPDOWN,
          label: "Testing Status",
          placeholder: "Select status",
          value: test?.id ? test?.status : "",
          name: "status",
          data: ["Passed", "Failed"],
          required: true,
        },
        {
          fieldType: "textarea",
          label: "Note",
          placeholder: "You have a .....",
          value: test?.id ? test?.note : "",
          name: "note",
          required: false,
          helpText:
            "You can leave suggestions or recommendation to developers on this feature. If the test of this feature fails, please add error message",
        },
        {
          fieldType: "file",
          label: "Screen Shots",
          name: "media",
          accepts: [".png", ".jpg", ".jpeg"],
          required: false,
          value: "",
          multiple: true,
          placeholder: "upload screenshots if any",
        },
      ]}
    />
  );
}

function RenderShots({ media }) {
  return (
    <div className="p-5">
      {media?.map((shot, index) => (
        <div className="mb-3">
          <p>{`Media ${index + 1}`}</p>
          <img
            src={shot?.file?.file}
            alt=" "
            onClick={() => goTo(shot?.file?.file)}
            className="cursor-pointer"
          />
        </div>
      ))}
    </div>
  );
}
