import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import React from "react";
import "./table-actions.css";
export default function TableActions({
  onDelete,
  onUpdate,
  onView,
  active,
  item,
  toggleShowMoreOptions,
  show,
}) {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      {item?.id === active && show ? (
        <div className="more-actions-container">
          {onUpdate ? (
            <div
              id="more-option-item"
              onClick={() => onUpdate(item)}
              className="elevate-float"
            >
              <PencilIcon className="h-5 w-5" />
            </div>
          ) : null}
          {onView ? (
            <div
              id="more-option-item"
              onClick={() => onView(item)}
              className="elevate-float"
            >
              <View />
            </div>
          ) : null}
          {onDelete ? (
            <div
              id="more-option-item"
              onClick={() => onDelete(item)}
              className="elevate-float"
            >
              <TrashIcon className="h-5 w-5" />
            </div>
          ) : null}
        </div>
      ) : null}
      <div
        className="more-icon-container"
        onClick={() => toggleShowMoreOptions(item?.id === active ? {} : item)}
      >
        <More vert={true} />
      </div>
    </div>
  );
}

function More({vert}) {
  return vert ? (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
</svg>
): (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
      />
    </svg>
  );
}

function View() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-5.5 h-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
      />
    </svg>
  );
}

export const TABLE_ACTION_TYPES = {
  DELETE: "delete",
  UPDATE: "update",
  VIEW: "view",
};
