import React from "react";
import { BAD, GOOD } from "../../../utils/constant";
import { capitalizeMe } from "../../../utils/utils";
 const low = (arr) => {
   return arr?.map((i) => i?.toLowerCase());
 };
function ProjectStatus({ status }) {
  let check = status?.toLowerCase();
 
  let color = "yellow";
  if (low(GOOD)?.includes(check)) {
    color = "green";
  } else if (low(BAD)?.includes(check)) {
    color = "red";
  }
  return (
    <div className="max-w-sm flex flex-row items-center justify-between w-full">
      <div className={`text-${color}-600 font-bold text-sm`}>
        {capitalizeMe(status)}
      </div>
    </div>
  );
}

export default ProjectStatus;
