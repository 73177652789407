import React from "react";
import ReactDOM from "react-dom";
import { Spinner } from "@kehillahglobal/ui";
import "./Loader.css";
export default function Loader({ style, close, text }) {
  return ReactDOM.createPortal(
     <div className="loading-container" style={style}>
      <Spinner
        thickness={5}
        variation={Spinner.TWO_HALF_CIRCLES_TYPE}
        color="#007aff"
      />
      <p
        style={{ margin: "20px 0px", fontSize: "1.2rem", cursor: "pointer" }}
        onClick={() => {
          if (close) close();
        }}
      >
        {text || " Loading..."}
      </p>
    </div>,
    document.body
  );
}