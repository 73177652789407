import { Modal } from "@kehillahglobal/ui";
import React from "react";
import ReactDOM from "react-dom";
export default function PopModal({ children, close, title, size }) {
  return ReactDOM.createPortal(
    <Modal close={() => close()} title={title} size={size}>
      {children}
    </Modal>,
    document.body
  );
}
