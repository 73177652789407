import React, { Fragment, useEffect, useState } from "react";
import {
  ChevronDownIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { Listbox, Transition } from "@headlessui/react";

const Pagination = ({ noOfItems, items, setItemsDisplayed }) => {
  const rows = [20, 50, 100];
  const [selected, setSelected] = useState(rows[0]);

  useEffect(() => {
    setPagination({
      startNum: 1,
      endNum: noOfItems > selected ? selected : noOfItems,
    });
  }, [noOfItems, selected]);

  const [pagination, setPagination] = useState({
    startNum: 1,
    endNum: noOfItems > selected ? selected : noOfItems,
  });

  const { startNum, endNum } = pagination;

  const increasePageNo = () => {
    if (endNum === noOfItems) {
      return;
    }
    if (endNum + selected <= noOfItems) {
      setPagination({
        startNum: startNum + selected,
        endNum: endNum + selected,
      });
    } else if (endNum + selected > noOfItems) {
      setPagination({ startNum: startNum + selected, endNum: noOfItems });
    }
  };

  const decreasePageNo = () => {
    if (startNum === 1) {
      return;
    }
    if (startNum - selected >= 1) {
      setPagination({
        startNum: startNum - selected,
        endNum: startNum - 1,
      });
    }
  };

  useEffect(() => {
    setItemsDisplayed(items.slice(startNum - 1, endNum));
  }, [selected, startNum, endNum, setItemsDisplayed, items]);

  return (
    <div className="flex flex-row w-full lg:w-4/5 justify-between pb-16">
      <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
          <div className="flex flex-col md:flex-row items-center md:space-x-4">
            <Listbox.Label className="text-xs font-bold">
              Rows per page
            </Listbox.Label>
            <div className="mt-1 relative">
              <Listbox.Button className="relative w-16 h-8 bg-white border-solid border-2 text-black border-deep-ash rounded-md shadow-sm px-3 cursor-default focus:outline-none text-xs font-bold">
                <span className="flex items-center space-x-1">
                  <p>{selected}</p>
                  <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-16 bg-white shadow-md max-h-24 rounded-md text-xs ring-1 ring-deep-ash overflow-auto focus:outline-none ">
                  {rows.map((row) => (
                    <Listbox.Option
                      key={row}
                      className={({ active }) =>
                        `cursor-default select-none p-2 ${
                          active ? "text-white bg-deep-ash" : "text-deep-ash"
                        }`
                      }
                      value={row}
                    >
                      {({ selected }) => (
                        <div className="flex space-x-4 items-center">
                          <div
                            className={({ selected }) =>
                              `ml-3 inline truncate ${
                                selected ? "font-semibold" : "font-normal"
                              }`
                            }
                          >
                            {row}
                          </div>
                          {selected ? (
                            <span
                              className={({ active }) =>
                                `inset-y-0 right-0 top-0 inline-block ${
                                  active ? "text-white" : "text-deep-ash"
                                }`
                              }
                            >
                              <CheckIcon
                                className="w-3 h-3"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
      {/* Pagination Component */}
      <div className="flex flex-col md:flex-row text-xs font-bold text-deep-ash space-y-2 md:space-y-0 md:space-x-4 items-center">
        <p>
          {noOfItems === 0 ? 0 : startNum} - {endNum} out of {noOfItems}
        </p>
        <div className="flex flex-row space-x-3 items-center">
          <ChevronLeftIcon
            className={`w-5 h-5 ${
              startNum === 1 ? "cursor-default opacity-50" : "cursor-pointer"
            }`}
            onClick={decreasePageNo}
          />
          <ChevronRightIcon
            className={`w-5 h-5 ${
              endNum === noOfItems
                ? "cursor-default opacity-50"
                : "cursor-pointer"
            } `}
            onClick={increasePageNo}
          />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
