import React, { useEffect, useState } from "react";
import { PencilIcon } from "@heroicons/react/solid";
import { Dropdown, Textbox } from "@kehillahglobal/ui";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "../../../SharedComponents";
import Loader from "../../../SharedComponents/Loader.js/Loader";
import {
  saveUserInfoAction,
} from "../../../store/actions";
import { formApiCall } from "../../../utils/api";
import { placeholderImage } from "../../../utils/constant";
import { UPDATE_USER_INFO } from "../../../utils/urls";
import './CompanySettings.css'
import { fileUploader } from "../../../utils/utils";

export default function CompanyAdminSettings() {
  let [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  // eslint-disable-next-line
  const { company, role, roles } = useSelector((state) => state.admin);

  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [country, setCountry] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    setName(user?.full_name);
    setUsername(user?.username);
    setCountry(user?.country);
    setImage(user?.image?.file);
  }, [user]);

  const handleSelectProfile = () => {
    let fileInput = document.getElementById("admin-image-input");
    fileInput.click();
   fileInput.onchange = async (e) => {
     setLoading(true);
     let image = await fileUploader(e.target.files[0]);
     if (image?.id) {
       formApiCall(UPDATE_USER_INFO, {
         id: user?.id,
         image_id: image?.id,
       }).then((res) => {
         setLoading(false);
         if (res?.success) {
           dispatch(saveUserInfoAction(res?.data));
           setImage(res?.data?.image?.file);
           toast.success("profile image updated successfully");
         } else {
           toast.error("An error occurred. Please try again");
         }
       });
     } else {
       setLoading(false);
       toast.error("An error occurred. Please try again");
     }
   };
  };

  const handleUpdateCompanyInfo = () => {
    setLoading(true);
    let data = {
      full_name: name,
      username,
      country,
    };

    formApiCall(UPDATE_USER_INFO, {
      ...data,
      id: user?.id,
    }).then((res) => {
      setLoading(false);
      if (res?.success) {
        dispatch(
          saveUserInfoAction(res?.data)
        );
        toast.success("Information updated successfully");
      } else {
        toast.error("An error occurred. Please try again.");
      }
    });
  };

  const renderEditForm = () => {
    return (
      <div className="mt-10">
        <div className="grid-repeat-2 mb-5">
          <div>
            <label class="block text-gray-700 text-sm font-bold" for="username">
              Full Name
            </label>
            <Textbox value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div>
            <label class="block text-gray-700 text-sm font-bold" for="username">
              Username
            </label>
            <Textbox
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-5">
          <label class="block text-gray-700 text-sm font-bold" for="username">
            Country
          </label>
          <Dropdown
            data={["Ghana", "Canada", "USA"]}
            placeholder={"Select Country"}
            value={country}
            onItemSelected={(current, x, y) => setCountry(current)}
          />
        </div>

        <div className="mt-10 flex-right">
          <Button
            name={"Save Changes"}
            fill={true}
            onClick={() => handleUpdateCompanyInfo()}
          />
        </div>
      </div>
    );
  };
  return (
    <>
      <div>
        <div className="profile-wrapper">
          <div className="profile-image-container">
            <input
              type={"file"}
              id="admin-image-input"
              className="hide"
              accept="image/*"
            />
            <img src={image || placeholderImage} alt="  " />
            <div
              className="change-profile-container"
              onClick={() => handleSelectProfile()}
            >
              <PencilIcon className="h-6 w-7" />
            </div>
          </div>
          <div className="ml-5">
            <h3 className="font-bold text-3xl tracking-tighter">
              {user?.full_name}
            </h3>
            <p className="tracking-tighter mb-3">{role || ""}</p>
            <span>{user?.email}</span>
          </div>
        </div>
        {/* <div className="mt-10">
          <Header text={"Roles"} size={3} />
          <div className="grid-repeat-4">
            {roles?.map((role, index) => (
              <div
                className="elevate-float interest-item"
                key={index?.toString()}
              >
                <p>{role?.role}</p>
              </div>
            ))}
          </div>
        </div> */}
        {renderEditForm()}
      </div>

      {loading && <Loader text={"processing.."} />}
    </>
  );
}
