import { NoItems, Table } from "@kehillahglobal/ui";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "../../../SharedComponents";
import PopModal from "../../../SharedComponents/popModal/PopModal";
import ProjectStatus from "../../../SharedComponents/ProjectInfo/components/ProjectStatus";
import { OWNER } from "../../../utils/constant";
import { getQuote } from "../../../utils/Quotes";
import { humanReadableDate, isEmpty } from "../../../utils/utils";
import "./CompanyBills.css";

function CompanyBills() {
  const bills = useSelector((state) => state.bills);
  const loggedInAdmin = useSelector((state) => state.admin);
  const [showPaymentModal, setShowPaymentModal] = useState(false)

  const prepareTableData = () => {
    return bills.map((bill, index) => [
      <p>{index + 1}</p>,
      <p>{humanReadableDate(bill?.created_at)}</p>,
      <p>{bill?.project}</p>,
      <p>v{bill?.version}</p>,
      <p>{bill?.amount}</p>,
      <ProjectStatus status={bill?.is_paid ? "Paid" : "Pending"} />,
      loggedInAdmin?.role === OWNER &&( !bill?.is_paid ? (
        <Button
          name={"Pay Now"}
          fill={true}
          onClick={() => alert("I will later route you to a payment page.")}
        />
      ) : (
        <>N/A</>
      ))
    ]);
  };

  return (
    <>
      <h1 className="font-bold text-4xl tracking-tighter mb-10">
        Bill History
      </h1>
      <div>
        {isEmpty(bills) ? (
          <NoItems text={getQuote()} />
        ) : (
          <Table
            columns={[
              "#",
              "Created At",
              "Project",
              "Order",
              "Amount",
              "Status",
              loggedInAdmin?.role === OWNER && "Action",
            ]}
            data={prepareTableData()}
            hoverAnimation={false}
          />
        )}
      </div>

      {showPaymentModal && (
        <PopModal
          close={() => setShowPaymentModal(false)}
          title="Pay Bill"
          size={"medium"}
          children={<PaymentComponent />}
        />
      )}
    </>
  );
}

export default CompanyBills;



function PaymentComponent(){
  return (
    <div>


      <center className="center-me">
        <Button name={"Cancel"} fill={false} style={{marginRight:20}}/>
        <Button name={"Pay Now"} fill={true}/>
      </center>
    </div>
  )
}
