export const jsonApiCall = async (url, payload) => {
  const res = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
    credentials: "include",
  });

  const data = await res.json();

  return data;
};

export const formApiCall = async (url, payload) => {
  const formData = new FormData();

  for (let name in payload) {
    formData.append(name, payload[name]);
  }

  const res = await fetch(url, {
    method: "POST",
    body: formData,
    credentials: "include",
  });

  const data = await res.json();

  return data;
};
