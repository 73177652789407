import React from "react";
import "./Landing.css";
import About from "../About/About";
// import Reviews from "../Reviews/Reviews";
import Home from "../Home/Home";
import Services from "../Services/Services";
// import Contact from "../Contact/Contact";
// import Pricing from "../Pricing/Pricing";
import Footer from "./components/Footer/Footer";

function Landing() {
  return (
    <div className="overflow-y-hidden">
      <Home />
      <Services />
      <About />
      {/* <Reviews /> */}
      {/* <Pricing /> */}
      {/* <Contact /> */}
      <Footer />
    </div>
  );
}

export default Landing;
