import React from "react";
import { FormGenerator } from "@kehillahglobal/ui";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formApiCall } from "../../../utils/api";
import { CREATE_COMPANY, SET_COMPANY_ID_TO_COOKIE } from "../../../utils/urls";
import "./createCompany.css";
import { COMPANY_PREFIX } from "../../../utils/constant";

function CompanyProfile() {
  const user = useSelector((state) => state.user);

  const setCompany = (company_id, company_admin_id) => {
    formApiCall(SET_COMPANY_ID_TO_COOKIE, {
      company_id,
      company_admin_id,
    });
  };
  const handleSubmit = async (data) => {
    try {
      data.user_id = user?.id;
      const res = await formApiCall(CREATE_COMPANY, data);
      localStorage.removeItem("createCompany");
      if (res?.success) {
        toast.success("Company Successfully created");
        const company = res?.data;
        setCompany(company?.id, company?.agent?.id);
        localStorage.setItem("prefix", COMPANY_PREFIX);
        return (window.location.href = "/" + COMPANY_PREFIX + "/dashboard");
      } else {
        toast.error(res?.error);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="create-company-root">
      <div className="elevate-float create-form-wrapper">
        <center className="mt-5">
          <div className="font-black text-5xl">
            Testable<span style={{ color: "var(--app-accent-pink)" }}>HQ</span>
          </div>
        </center>
        <FormGenerator
          title={""}
          subtitle={""}
          onSubmit={(data, reset) => {
            handleSubmit(data);
          }}
          formSubmitBtnText="Create Company"
          elevation={0}
          fields={[
            {
              fieldType: "input",
              label: "Your Company Name",
              placeholder: "Amazon Inc ",
              name: "name",
              value: "",
              required: true,
            },
          ]}
        />
      </div>
    </div>
  );
}

export default CompanyProfile;
