import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import About from "./pages/About/About";
import Success from "./pages/auth/Success";
import Projects from "./pages/Company/projects";
import ProjectDetail from "./pages/Company/projects/project-details/ProjectDetail";
import PageWrapper from "./SharedComponents/pageWrapper/PageWrapper";
import {
  fetchAllAdminCompaniesAction,
  fetchAllAgentInvitationsAction,
  fetchAllAgentsAction,
  fetchAllCompanyBillsAction,
  fetchAllProjectsAction,
  fetchAllTestersProjectTestAction,
  getCompanyAdminInfoAction,
  saveUserInfoAction,
  setPrefixAction,
} from "./store/actions";
import { formApiCall } from "./utils/api";
import { WHOAMI } from "./utils/urls";
import "./General.css";
import CompanyAdminWrapper from "./pages/Company/CompanyAdmins/CompanyAdminWrapper";
import Landing from "./pages/Landing/Landing";
import CreateCompany from "./pages/Company/CreateCompany/CreateCompany";
import CompanyJoinInvitation from "./pages/Company/CompanyInvitationCode/CompanyJoinInvitation";
import ProjectTestDetail from "./pages/Company/projects/project-details/project-detail-tabs/ProjectTestDetail";
import TestingAgentTests from "./pages/TestingAgent/TestingAgentTest/TestingAgentTests";
import CreateTester from "./pages/TestingAgent/CreateTester/CreateTester";
import TesterTestDetails from "./pages/TestingAgent/TestingAgentTest/TesterTestDetails";
import TestingAgentProfile from "./pages/TestingAgent/TestingAgentProfile/TestingAgentProfile";
import CompanySettings from "./pages/Company/CompanySettings/CompanySettings";
import CompanyAdminSettings from "./pages/Company/CompanySettings/CompanyAdminSettings";
import CompanyBills from "./pages/Company/CompanyBills/CompanyBills";
import CompanyDashboard from "./pages/Company/CompanyDashboard/CompanyDashboard";
import CompanyAudits from "./pages/Company/CompanyAudits/CompanyAudits";
import TestingAgentDashboard from "./pages/TestingAgent/TestingAgentDashboard/TestingAgentDashboard";
import TesterPayment from "./pages/TestingAgent/TesterPayment/TesterPayment";
import ConfirmAccount from "./pages/auth/ConfirmAccount";
import CompanySelection from "./pages/auth/CompanySelection";
import { COMPANY_PREFIX, TESTER_PREFIX } from "./utils/constant";
import { VERSION } from "./version";
import ActivityIndicator from "./SharedComponents/Loader.js/ActivityIndicator";
import Error from './pages/404/404'
import CompanyInvitation from "./pages/Company/CompanyInvitation/CompanyInvitation";
function App() {
  const user = useSelector((state) => state.user);
  const prefix = useSelector((state) => state.prefix);
  const dispatch = useDispatch();

  const getStartUpData = () => {
    dispatch(fetchAllProjectsAction());
    dispatch(getCompanyAdminInfoAction());
    dispatch(fetchAllAgentsAction());
    dispatch(fetchAllAgentInvitationsAction());
    dispatch(fetchAllAdminCompaniesAction())
  };
  
  
  const genericSetupData = ()=>{
    dispatch(fetchAllCompanyBillsAction())

  }

  const getSetupDataForTesters = () => {
    dispatch(fetchAllTestersProjectTestAction())
  };

  const AuthenticatedRoutes = () => {
    const params = window.location.href.toString().split("entry=")[1];

    if (!user?.id) {
      return <ActivityIndicator />;
    } else if (!params) {
      if (!user?.id) {
        return window.location.href = "/";
      }
    }
      return (
        <PageWrapper>
          <Outlet />
        </PageWrapper>
      );
  };

  useEffect(() => {
    if (!user?.id) {
      const currentPrefix = window.location.href.split("/")[3];
      formApiCall(WHOAMI, {}).then((res) => {
        if (res?.data) {
          dispatch(saveUserInfoAction(res?.data));
          dispatch(setPrefixAction(currentPrefix));
          genericSetupData()
          if (res?.data?.is_testing_agent && currentPrefix === TESTER_PREFIX) {
            getSetupDataForTesters();
          } else if (res?.data?.is_company_admin && currentPrefix === COMPANY_PREFIX) {
            getStartUpData();
          }
        } else {
          if (window.location.pathname !== "/") {
            if (
              !window.location.pathname.toString().includes("/success/") &&
              !window.location.pathname.toString().includes("/invitation/join/")
            ) {
              console.log("====== here =====", window.location.pathname);
              localStorage.clear();
              window.location.pathname = "/";
            }
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [user?.id, dispatch]);

  const knownRoutes = ["company", "tester"]
  const isUnknownRoutes = knownRoutes.find((route) =>
    window.location.href.includes(route)
  );


  return (
    <>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/success" element={<Success />} />
        <Route path="/invitation/join/:code" element={<CompanyInvitation />} />
        <Route path="/account/type/confirmation" element={<ConfirmAccount />} />
        <Route path="/company/account/selection" element={<CompanySelection />}/>
        <Route path="/aboutUs" element={<About />} />
        <Route path="/register/company" element={<CreateCompany />} />
        <Route path="/register/tester" element={<CreateTester />} />
        <Route path="/company/admin/join-with-code" element={<CompanyJoinInvitation />}/>
        <Route element={<AuthenticatedRoutes />}>
          <Route path={`/${prefix}/dashboard`} element={<CompanyDashboard />} />
          <Route path={`/${prefix}/settings`} element={<CompanySettings />} />
          <Route path={`/${prefix}/admin/profile`} element={<CompanyAdminSettings />}/>
          <Route path={`/${prefix}/bills`} element={<CompanyBills />} />
          <Route path={`/${prefix}/audit/logs`} element={<CompanyAudits />} />
          <Route path={`/${prefix}/agents`} element={<CompanyAdminWrapper />} />
          <Route path={`/${prefix}/projects`} element={<Projects />} />
          <Route path={`/${prefix}/projects/:id`} element={<ProjectDetail />} />
          <Route path={`/${prefix}/projects/:id/tests/:testID`} element={<ProjectTestDetail />} />

          {/* TESTING AGENT ROUTES */}
          <Route path={`/${prefix}/tests/`} element={<TestingAgentTests />} />
          <Route path={`/${prefix}/tests/:orderID/details`}element={<TesterTestDetails />}/>
          <Route path={`/${prefix}/profile`} element={<TestingAgentProfile />}/>
          <Route path={`/${prefix}/home`} element={<TestingAgentDashboard />} />
          <Route path={`/${prefix}/payments`} element={<TesterPayment />} />
        </Route>
        {!isUnknownRoutes && (<Route path='*' element={<Error />} />)}

      </Routes>
      <div className="thq-versioning">
        <span>{VERSION?.split("@")[1]}</span>
      </div>
      <ToastContainer />
    </>
  );
}

export default App;
