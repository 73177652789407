import { Component } from "react";
import "./error.css";
import ErrorBoundarySvg from "./ErrorBoundarySvg";
import {Button} from "@kehillahglobal/ui";
import XIcon from "../../SharedComponents/icons/XIcon";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: "", errorInfo: "", hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo }, () =>
      console.table({ error, errorInfo })
    );
  }

  sendErrorReport = () => {
    alert("Report Sent! Thanks for reporting this error");
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-page-container">
          <center>
            <ErrorBoundarySvg />
            <h3 style={{ fontWeight: "500" }}>Oops! something went wrong</h3>
            <br />
            <a className="more-info" href="#oops">
              See Cause
            </a>
            <span style={{ margin: "0 1rem" }}>|</span>
            <a
              href="/dashboard"
              style={{
                color: "#833e83",
                textDecoration: "none",
              }}
            >
              Go to "Home"
            </a>
          </center>
          <div id="oops" className="error-log">
            <div className="error-report">
              <h1>Error Message</h1>
              <br></br>
              <a className="close-error-log" href="#void">
                <XIcon />
              </a>
              {this.state.error.message}
              <br></br>
              <br></br>
              <Button onClick={this.sendErrorReport}>Report</Button>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
