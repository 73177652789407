import React from 'react'
import './card.css'

export default function Card({title, count, primaryColor, secondaryColor,icon, onClick}) {
  return (
    <div
      id="figure-container"
      className="elevate-float card-me"
      style={{ backgroundColor: primaryColor }}
      onClick={()=> onClick && onClick()}
    >
      <div id="avatar" style={{ backgroundColor: secondaryColor }}>
        {icon}
      </div>
      <div id="figures-details">
        <span>{title}</span>
        <span>{count}</span>
      </div>
    </div>
  );
}
