import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { MenuAlt3Icon, XIcon } from "@heroicons/react/solid";

export function HomeMenu({ routes }) {
  //menu items in the dropdown list
  const MenuItem = ({ name, icon }) => {
    return (
      <div className="flex flex-row w-full px-3 justify-start items-center space-x-3">
        <div>{icon}</div>
        <div className="font-medium tracking-tight">{name}</div>
      </div>
    );
  };

  return (
    <div>
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="">
                {!open ? (
                  <MenuAlt3Icon
                    className="ml-2 -mr-1 h-8 w-8 text-gray-900 hover:text-gray-400"
                    aria-hidden="true"
                    color="black"
                  />
                ) : (
                  <XIcon
                    className="ml-2 -mr-1 h-5 w-5 text-nice-blue hover:text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1">
                  {routes.map((route) => {
                    return (
                      <div className="divide-y-2" key={route.id}>
                        <Menu.Item>
                          {({ active }) => (
                            <p
                              onClick={() =>{
                                route?.onClick && route?.onClick()
                              }}
                              className={`${
                                active ? "bg-gray-100" : "text-gray-900"
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            >
                              <MenuItem name={route.name} icon={route.icon} />
                            </p>
                          )}
                        </Menu.Item>
                      </div>
                    );
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}
