import { FormGenerator } from "@kehillahglobal/ui";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Notice } from "../../../SharedComponents";
import { updateProjectListLocallyAction } from "../../../store/actions";
import { formApiCall } from "../../../utils/api";
import { CREATE_PROJECT, ORDER_A_TEST } from "../../../utils/urls";

export default function OderTest({ close }) {
  const projects = useSelector((state) => state.projects);
  const prefix = useSelector((state) => state.prefix);
      const [showNotice, setShowNotice] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCreateTestOrder = (data, reset) => {

    let toSend = {
      project_id: data?.project?.id,
      ...data,
      testing_sheet: data.testing_sheet?.data?.file?.data,
      file: data.file?.data?.file?.data,
      number:data?.number[0]
    };
    if (!toSend?.file) {
      delete toSend.file;
    }
    if (!toSend?.testing_sheet) {
      delete toSend.testing_sheet;
    }

    formApiCall(ORDER_A_TEST, toSend).then((res) => {
      close();
      if (res?.success) {
        navigate(`/${prefix}/projects/${data?.project?.id}/tests/${res?.data?.id}/`);
      } else {
        toast.error(res?.error);
      }
    });
  };

  let fields = [
    {
      fieldType: FormGenerator.Fields.DROPDOWN,
      label: "what project does this order belong to",
      placeholder: "Select a project",
      value: "",
      name: "project",
      data: projects || [],
      required: true,
      labelExtractor: (item) => item?.name,
      valueExtractor: (item) => item?.id,
      child: {
        fields: [
          {
            fieldType: FormGenerator.Fields.INPUT,
            label: "Project Name",
            name: "name",
            placeholder: "Enter name of project",
            required: true,
            value: "",
          },
        ],
        metaData: {
          endpoint: CREATE_PROJECT,
          onSuccess: (res) => {
            if (res?.success) {
              let new_projects = [res?.data, ...projects];
              dispatch(updateProjectListLocallyAction(new_projects));
            }
          },
        },
      },
    },
    {
      fieldType: FormGenerator.Fields.TAG,
      label: "Enter version number and press 'Enter' or 'Return' to add ",
      placeholder: "1.0.0",
      value: "",
      name: "number",
      required: true,
    },
    {
      fieldType: FormGenerator.Fields.DATE,
      label: "Deadline",
      placeholder: "By what date do you need this completed ?.",
      value: "",
      name: "deadline",
      required: true,
    },
    {
      fieldType: "file",
      label: "Testing sheet(a file containing the feature to be tested)",
      name: "testing_sheet",
      accepts: [".csv", ".xls", ".xlsx"],
      required: true,
      value: "",
      placeholder: "supported format (.csv)",
    },
    {
      fieldType: "input",
      label: "Version URL",
      placeholder: "http://companyxyzbeta.tech",
      value: "",
      name: "url",
      required: false,
    },
    {
      fieldType: "file",
      label: "software zip",
      name: "file",
      accepts: [".zip"],
      required: false,
      value: "",
      placeholder: "select a software zip for this version",
    },
    {
      fieldType: FormGenerator.Fields.RICHTEXT,
      label: "Extra Version Instructions For Testers",
      placeholder: "testing instructions here",
      value: "",
      name: "instructions",
      required: false,
    },
  ];
  return (
    <div>
      <Notice
        notice={showNotice}
        handleClose={() => setShowNotice(!showNotice)}
      />
      <FormGenerator
        title={""}
        subtitle={""}
        onSubmit={(data, reset) => handleCreateTestOrder(data, reset)}
        formSubmitBtnText={"Place order"}
        elevation={0}
        fields={fields}
      />
    </div>
  );
}
