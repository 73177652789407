import {
  CheckIcon,
  FolderIcon,
  ShoppingCartIcon,
} from "@heroicons/react/outline";
import { NoItems, Table } from "@kehillahglobal/ui";
import React, { useEffect, useState } from "react";
import { Button } from "../../../SharedComponents";
import Card from "../../../SharedComponents/card/Card";
import Header from "../../../SharedComponents/Header/Header";
import XIcon from "../../../SharedComponents/icons/XIcon";
import Loader from "../../../SharedComponents/Loader.js/Loader";
import PopModal from "../../../SharedComponents/popModal/PopModal";
import ProjectStatus from "../../../SharedComponents/ProjectInfo/components/ProjectStatus";
import { formApiCall } from "../../../utils/api";
import { getQuote } from "../../../utils/Quotes";
import { GET_COMPANY_DASHBOARD_DATA } from "../../../utils/urls";
import { humanReadableDate, isEmpty } from "../../../utils/utils";
import OderTest from "./OderTest";

function CompanyDashboard() {
  const [data,setData] = useState({})
  const [requestTest, setRequestTest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true)
    formApiCall(GET_COMPANY_DASHBOARD_DATA,{}).then(res=>{
      setIsLoading(false)
      if(res?.success){
        setData(res?.data)
      }
    })
  }, [])
  
  const stats = [
    {
      title: "Projects",
      count: data?.projects_count || 0,
      icon: <FolderIcon className="h-6 w-7" color="white" />,
      primaryColor: "#E67D21",
      secondaryColor: "#D35400",
      onClick: () => alert("hello"),
    },
    {
      title: "Test Orders",
      count: data?.orders_count ||0,
      icon: <ShoppingCartIcon className="h-6 w-7" color="white" />,
      primaryColor: "#3498DB",
      secondaryColor: "#007aff",
      onClick: () => alert("hello"),
    },
    {
      title: "Features Passed",
      count: data?.features?.passed ||0,
      icon: <CheckIcon className="h-6 w-7" color="white" />,
      primaryColor: "#229954",
      secondaryColor: "#1E8449",
      onClick: () => alert("hello"),
    },
    {
      title: "Features Failed",
      count: data?.features?.failed|| 0,
      icon: <XIcon color={"white"} size={6} />,
      primaryColor: "#E74C3C",
      secondaryColor: "#A93226",
      onClick: () => alert("hello"),
    },
  ];
  const prepareLatestOrdersTableData = () => {
    return (data?.latest_orders || []).map((item, index) => [
      <p>{index + 1}</p>,
      <p>{humanReadableDate(item?.created_at)}</p>,
      <p>{humanReadableDate(item?.deadline)}</p>,
      <p>{item?.project}</p>,
      <p>v{item?.version}</p>,
      <p>{item?.features||0}</p>,
      <p style={{ color: "#239B56" }}>{item?.stats?.passed||0}</p>,
      <p style={{ color: "#E74C3C" }}>{item?.stats?.failed ||0}</p>,
      <ProjectStatus status={item?.status} />,
    ]);
  };
  return isLoading? <Loader/> :(
    <>
      <div className="grid-repeat-4">
        {stats?.map((item, index) => {
          return (
            <Card
              title={item?.title}
              count={item?.count}
              primaryColor={item?.primaryColor}
              secondaryColor={item?.secondaryColor}
              onClick={item?.onClick}
              icon={item?.icon}
            />
          );
        })}
      </div>

      <div className="mt-10">
        <div className="space-me mb-10">
          <div></div>
          <Button
            name={"Order a Test"}
            onClick={() => setRequestTest(true)}
          ></Button>
        </div>

        <Header text={"Latest Orders"} size={4} />
        {isEmpty(data?.latest_orders) ? (
          <NoItems text={getQuote()} />
        ) : (
          <Table
            columns={[
              "#",
              "Date",
              "Deadline",
              "Project",
              "Version",
              "Features",
              "Passed",
              "Failed",
              "Status",
            ]}
            data={prepareLatestOrdersTableData()}
            hoverAnimation={false}
          />
        )}
      </div>

      {requestTest && (
        <PopModal
          close={() => setRequestTest(false)}
          title="Order a Test Form"
          size={"large"}
          children={<OderTest close={() => setRequestTest(false)} />}
        />
      )}
    </>
  );
}

export default CompanyDashboard;
