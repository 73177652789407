import React from "react";
import "./Services.css";
import { dummyCompanyImage } from "../../utils/constant";

function Services() {
  const testServices = [
    {
      title: "Mobile App testing",
      image: dummyCompanyImage,
      context:
        " verifying whether an app designed for handheld devices meets certain mobile testing requirements",
      hidden: "Tempor esse veniam eiusmod magna cupidatat.",
    },
    {
      title: "Web App Testing",
      image: dummyCompanyImage,
      context:
        " examine the functionality, security, usability and stability of the app after it is deployed",
      hidden: "Tempor esse veniam eiusmod magna cupidatat.",
    },
    {
      title: "Desktop App Testing",
      image: dummyCompanyImage,
      context:
        "ensure the quality and functionalities of the app according to the requirements",
      hidden: "Tempor esse veniam eiusmod magna cupidatat.",
    },
  ];

  return (
    <div className="mx-auto w-full my-10 bg-[#fafafa] text-center p-10 box-border">
      <h3 className="font-black tracking-tighter text-2xl">
        testable<span className="text-nice-blue">HQ</span>{" "}
      </h3>

      <h1 className="font-bold text-center text-4xl md:text-6xl mt-12 mb-12 tracking-tighter">
        <span class="text-nice-blue"> Services </span> that will help you grow
        <br />
        your app fast
      </h1>

      <div className="w-full flex flex-col items-center justify-center space-y-8">
        {testServices &&
          testServices.map((service) => {
            return (
              <div className="bg-white p-10 max-w-sm md:max-w-3xl justify-center w-full flex flex-col md:flex-row items-center md:space-x-4 box-border space-y-4 md:space-y-0">
                <div className="w-16 h-16 md:h-20 md:w-20">
                  <img alt="test" className="w-full" src={service.image} />
                </div>

                <div className="space-y-2 md:space-y-1">
                  <div className="font-bold text-gray-900 md:text-lg text-sm md:text-start text-center">
                    {service.title}
                  </div>
                  <div className="text-gray-700 font-medium md:text-start max-w-xs md:max-w-none text-xs text-center md:text-sm">
                    {service.context}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Services;
