import React from "react";
import { FormGenerator } from "@kehillahglobal/ui";

import { CREATE_PROJECT, UPDATE_PROJECT_INFO } from "../../../../utils/urls";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateProjectListLocallyAction } from "../../../../store/actions";
import { formApiCall } from "../../../../utils/api";


export default function CreateAndUpdateProject(props) {
  let projects = useSelector((state) => state.projects) || [];
  const dispatch = useDispatch();

  const handleSubmit = async (data) => {
    if (props.toEdit?.id) {
        data.id = props?.toEdit?.id
    }

    formApiCall(
      props?.toEdit?.id ? UPDATE_PROJECT_INFO : CREATE_PROJECT,
      data
    ).then((res) => {
      if (res?.success) {
        if (props?.toEdit?.id) {
          projects = projects?.filter((item) => item?.id !== res?.data?.id);
        }
        let newProjects = [res?.data, ...projects];
        dispatch(updateProjectListLocallyAction(newProjects));
        toast.success("Project Successfully created");
      } else {
        toast.error(res?.error);
      }
      props.close();
    });
  };

  const fields = [
    {
      fieldType: "input",
      label: "Project name",
      placeholder: "enter project name",
      value: props.toEdit?.id ? props?.toEdit?.name : "",
      name: "name",
      required: true,
    }
  ];

  return (
    <div>
      <FormGenerator
        title={""}
        subtitle={""}
        onSubmit={(data, reset) => {
          handleSubmit(data);
        }}
        formSubmitBtnText={"Submit"}
        elevation={0}
        fields={fields}
      />
    </div>
  );
}
