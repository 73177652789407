import React from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../../SharedComponents/Header/Header';


export default function ConfirmAccount() {
    const navigate = useNavigate()
  return (
    <div className="create-company-root">
      <div className="elevate-float confirm-account-wrapper">
        <center>
          <Header text={"Account not Found"} size={3} />
        </center>
    
        <p>
          Proceed to signup as a{" "}
          <span className="link" onClick={() => navigate("/register/company")}>
            Company
          </span>{" "}
          or{" "}
          <span className="link" onClick={() => navigate("/register/tester")}>
            Tester
          </span>
        </p>
      </div>
    </div>
  );
}
