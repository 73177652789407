import { FormGenerator } from "@kehillahglobal/ui";
import React from "react";
import { toast } from "react-toastify";
import { formApiCall } from "../../../../../utils/api";
import { CREATE_REVIEW, UPDATE_REVIEW } from "../../../../../utils/urls";

export default function ProjectReviewForm({ reviews,updateTest, testOrder, review, close, toggleShowMoreOptions }) {

  const handleCreateReview = (data) => {
    let toSend = {
      ...data,
      test_order_id: testOrder?.id,
    };

    if (review?.id) {
      toSend.id = review?.id;
      delete toSend.test_order_id
    }

    formApiCall(review?.id ? UPDATE_REVIEW : CREATE_REVIEW, toSend).then(
      (res) => {
        if (res?.success) {
          let newReviews = reviews || [];
          if (review?.id) {
            newReviews = newReviews?.filter(
              (item) => item?.id !== res?.data?.id
            );
          }
          newReviews = [res?.data, ...newReviews];
          testOrder.reviews = newReviews;
          updateTest(testOrder);
          close();
          toast.success("Review successfully created");
        } else {
          toast.error(res?.error);
        }
      }
    );
  };


  return (
    <FormGenerator
      title={""}
      subtitle={""}
      onSubmit={(data, reset) => handleCreateReview(data, reset)}
      formSubmitBtnText={review?.id ? "Update Review" : "Create Review"}
      elevation={0}
      fields={[
        {
          fieldType: "increment",
          label: "Rating",
          value: review?.id ? review?.rating : "",
          name: "rating",
          required: true,
          min: 1,
          max: 5,
        },
        {
          fieldType: "textarea",
          label: "Review note",
          placeholder: "You have a .....",
          value: review?.id ? review?.note : "",
          name: "note",
          required: true,
        },
      ]}
    />
  );
}
