import { BellIcon, SearchIcon } from "@heroicons/react/outline";
import { Textbox } from "@kehillahglobal/ui";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { placeholderImage } from "../../utils/constant";
import { capitalizeMe } from "../../utils/utils";
import Header from "../Header/Header";
import "./NavBar.css";
const NO_RECORD = "No Record Found";

export default function Navbar() {
  const [query, setQuery] = useState("");
  const projects = useSelector((state) => state.projects || []);
  const prefix = useSelector((state) => state.prefix);
  const admins = useSelector((state) => state.admins || []);
  const testFeatures = useSelector((state) => state.testFeatures || []);
  const keys = ["name", "email", "full_name"];
  const [searchResults, setSearchResults] = useState("");

  const navigate = useNavigate();

  const search = (data, searchText) => {
    return data?.filter((item) =>
      keys?.some((key) =>
        item[key]?.toLowerCase()?.includes(searchText?.toLowerCase())
      )
    );
  };

  const handleSearch = (searchText) => {
    setQuery(searchText);
    let pros = search(projects, searchText);
    let adms = search(admins?.map((admin) => admin?.user) || [], searchText);
    let features = search(testFeatures, searchText);

    if([...pros, ...adms, ...features].length<1){
      setSearchResults(NO_RECORD);
      return
    }
    setSearchResults({ projects: pros,admins: adms,features: features});
    if (!searchText) setSearchResults("");
  };

  const renderProjectsResults = (item) => {
    return (
      <p
        className="cursor-pointer px-3 py-1 hover:bg-fuchsia-900 hover:text-white"
        onClick={() => {
          setSearchResults("");
          setQuery("");
          navigate(`/${prefix}/projects/${item?.id}`);
        }}
      >
        {item?.name}
      </p>
    );
  };

  const renderAdminsResults = (item) => {
    return (
      <div
        className="cursor-pointer px-3 py-1 hover:bg-fuchsia-900 hover:text-white flex-me"
        onClick={() => {
          setSearchResults("");
          setQuery("");
          navigate(`/${prefix}/agents`);
        }}
      >
        <img
          src={item?.image?.file || placeholderImage}
          alt=" "
          className="w-8 h-8 rounded mr-3 obj-fit-cover"
        />
        <p>{item?.name}</p>
      </div>
    );
  };

  return (
    <div>
      <div className="nav-main-container elevate-float">
        <div
          className="nav-search-area"
          style={{
            flex: "10",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            padding: "10px 0px",
          }}
        >
          <SearchIcon className="h-6 w-7" />
          <Textbox
            className="nav-search-input"
            containerStyle={{ display: "inline" }}
            placeholder="Search for projects"
            value={query}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div
          style={{
            flex: "2",
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <span className="noti-bell">
            <BellIcon className="h-6 w-7" />
          </span>
        </div>
      </div>

      {/* ---------------- Dropdown area ----------- */}
      {searchResults && (
        <>
          <div
            className="nav-drop-ghost-curtain"
            onClick={() => setSearchResults("")}
          ></div>
          <div className="nav-dropdown-main elevate-float pb-3">
            {searchResults === NO_RECORD ? (
              <div className="center-me mt-10">
                <Header text={NO_RECORD} size={2} />
              </div>
            ) : (
              Object.entries(searchResults)?.map(([name, items]) => {
                if (items?.length) {
                  return (
                    <div key={name} className="">
                      <p className="font-bold tracking-tighter px-3 py-1">
                        In {capitalizeMe(name)}
                      </p>
                      <div className="">
                        {items?.map((item) => {
                          switch (name) {
                            case "admins":
                              return renderAdminsResults(item);
                            case "projects":
                              return renderProjectsResults(item);
                            default:
                              return <span></span>;
                          }
                        })}
                      </div>
                    </div>
                  );
                }
                return <></>;
              })
            )}
          </div>
        </>
      )}
    </div>
  );
}
