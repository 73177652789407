
export const SHOW_SIDE_PANE = "SHOW_SIDE_PANE";
export const SAVE_LOGGED_IN_USER_INFO = "SAVE_LOGGED_IN_USER_INFO";
export const LOAD_ALL_PROJECTS = "LOAD_ALL_PROJECTS";
export const SAVE_CURRENT_COMPANY = "SAVE_CURRENT_COMPANY";
export const LOAD_SETUP_DATA = "LOAD_SETUP_DATA";
export const GET_COMPANY_AGENT_INFO = "GET_COMPANY_AGENT_INFO";
export const LOAD_COMPANY_AGENTS = "LOAD_COMPANY_AGENTS";
export const LOAD_COMPANY_AGENT_INVITATIONS = "LOAD_COMPANY_AGENT_INVITATIONS";
export const SET_PREFIX = "SET_PREFIX";
export const LOAD_PROJECT_TESTS_FOR_TESTER = "LOAD_PROJECT_TESTS_FOR_TESTER";
export const LOAD_COMPANY_BILLS = "LOAD_COMPANY_BILLS";
export const LOAD_COMPANY_AUDIT_LOGS = "LOAD_COMPANY_AUDIT_LOGS";
export const LOAD_ADMIN_COMPANIES = "LOAD_ADMIN_COMPANIES";
