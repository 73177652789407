import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
export default function ConfirmationModal({
  close,
  onDelete,
  msg,
}) {
  return ReactDOM.createPortal(
    <div className="confirmation-modal-root" onClick={() => close()}>
      <div className="elevate-float content-container">
        <center>
          <ExclamationCircleIcon color="tomato" className="h-20 w-20" />
          <h3 className="font-bold text-2xl tracking-tighter mb-3">Warning</h3>
        </center>
        <div style={{ padding: "0px 15px" }}>
          <p>
            {msg ||
              "Are you sure you want to delete this item ?\n This action can not be undone."}
          </p>
        </div>
        <div className="confirmation-footer">
          <div className="cancel-text" onClick={() => close()}>
            <p>Cancel</p>
          </div>
          <div className="delete-btn" onClick={() => onDelete()}>
            <p>Delete</p>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
}
