export const SIGNUP_AS_TESTER = "Sign_up_as_tester"
export const LOGIN_AS_TESTER = "Sign_in_as_tester"
export const LOGIN_AS_COMPANY_ADMIN = "Sign_in_as_company_admin"

export   const COMPANY_PREFIX = "company";
export   const TESTER_PREFIX = "tester";

export const COMPLETED = "Completed";
export const TESTING = "In Progress";
export const CREATED = "Created";
export const ACCEPTED = "Accepted";
export const DECLINED = "Declined";
export const PENDING = "Pending";

export const GOOD = ['Passed', COMPLETED, "Created", "Paid", ACCEPTED]
export const BAD = ["Failed", "Deleted", DECLINED]

export const placeholderImage = "https://i.ibb.co/0DRHM5M/user5.jpg";
export const dummyCompanyImage =
  "https://static3.eyellowpages.ph/assets/placeholder-company-30f7247c043cac59226403f669f2afea709784b1f52cc405c90672c3d9ec428a.jpg";
export const ADMIN = "Admin"
export const OWNER = "Owner"