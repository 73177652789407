import {
  PencilAltIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import { StarIcon } from "@heroicons/react/solid";
import { NoItems, Table, TabView } from "@kehillahglobal/ui";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadCrumbs } from "../../../../../SharedComponents";
import Header from "../../../../../SharedComponents/Header/Header";
import ActivityIndicator from "../../../../../SharedComponents/Loader.js/ActivityIndicator";
import PopModal from "../../../../../SharedComponents/popModal/PopModal";
import ProjectStatus from "../../../../../SharedComponents/ProjectInfo/components/ProjectStatus";
import { formApiCall } from "../../../../../utils/api";
import { getQuote } from "../../../../../utils/Quotes";
import {
  GET_TEST_ORDER_INFO,
  UPDATE_TEST_ORDER_INFO,
} from "../../../../../utils/urls";
import { goTo, humanReadableDate, isEmpty } from "../../../../../utils/utils";
import TestRequestForm from "../TestRequestForm";
import ProjectReviewForm from "./ProjectReviewForm";
import ProjectTestFeatures from "./ProjectTestFeatures";

export default function ProjectTestDetail() {
  const { id, testID } = useParams();
  const [testOrder, setTestOrder] = useState({});
  const [selectedReview, setSelectedReview] = useState({});
  const [showAddReview, setShowAddReview] = useState(false);
  const [showEditTest, setShowEditTest] = useState(false);
  // eslint-disable-next-line
  const [changer, setChanger] = useState("test-instructions");
  const project = useSelector(
    (state) => state.projects.filter((pro) => pro?.id === id)[0]
  );
  const prefix = useSelector((state) => state.prefix);

  useEffect(() => {
    formApiCall(GET_TEST_ORDER_INFO, { order_test_id: testID }).then((res) => {
      if (res?.success) {
        setTestOrder(res?.data);
      }
    });
  }, [testID]);

  const breadCrumb = [
    {
      id: 1,
      name: "projects",
      redirect: `/${prefix}/projects/`,
    },
    {
      id: 2,
      name: project?.name,
      redirect: `/${prefix}/projects/${id}`,
    },
    {
      id: 3,
      name: "v" + testOrder?.version?.number + " Test",
      redirect: `/${prefix}/projects/${id}/tests/${testID}`,
    },
  ];

  const getSourceFile = () => {
    return isEmpty(testOrder?.file?.file) ? testOrder?.url : testOrder?.file?.file;
  };
  const handleUpdateTestOrder = (data, reset) => {
    let toSend = {
      id: testOrder?.id,
      ...data,
      testing_sheet: data.testing_sheet?.data?.file?.data,
      file: data.file?.data?.file?.data,
    };
    if (!toSend?.file) {
      delete toSend.file;
    }
    if (!toSend?.testing_sheet) {
      delete toSend.testing_sheet;
    }
    delete toSend?.number;

    formApiCall(UPDATE_TEST_ORDER_INFO, toSend).then((res) => {
      if (res?.success) {
        setShowEditTest(false);
        toast.success("Test request successful updated !");
        setTestOrder(res?.data);
      } else {
        toast.error(res?.error);
      }
    });
  };

  const showReviewContent = (review) => {
    return (
      <div>
        <div style={{ display: "flex" }} className="mb-2">
          {new Array(Number(review?.rating)).fill(null).map(() => (
            <StarIcon className="h-4 w-4 " color="var(--app-accent-pink)" />
          ))}
        </div>
        <div>
          <h1>{review?.title}</h1>
          <small style={{ textAlign: "justify" }}>{review?.note}</small>
        </div>
      </div>
    );
  };
  const prepareTableData = () => {
    let { reviews } = testOrder;
    return reviews?.map((review, index) => [
      <p>{index + 1}</p>,
      <p>{humanReadableDate(review?.created_at)}</p>,
      showReviewContent(review),
      <div
        className="edit-test-btn-container"
        onClick={() => {
          setShowAddReview(true);
          setSelectedReview(review);
        }}
      >
        <span>Edit Review</span>
      </div>,
    ]);
  };

  const TABS = [
    {
      name: "Test Feature",
      id: "test-features",
      component: <ProjectTestFeatures testOrder={testOrder} setTestOrder={setTestOrder} />,
    },
    {
      name: "Test Instructions",
      id: "test-instructions",
      component: (
        <div
          className="justify rich-text"
          dangerouslySetInnerHTML={{
            __html: testOrder?.instructions || `No Instructions Added`,
          }}
        ></div>
      ),
    },
    {
      name: "Test Reviews",
      id: "test-reviews",
      component: 
        !isEmpty(testOrder?.reviews) ?(
          <div>
            <div
              className="add-item-container"
              onClick={() => {
                setShowAddReview(true);
                setSelectedReview({});
              }}
            >
              <PlusIcon className="h-6 w-7" />
              <p>New Review</p>
            </div>
            <Table
              columns={["#", "Date", "Review", "Actions"]}
              data={prepareTableData()}
              hoverAnimation={false}
              tableClass={"project-review-table"}
            />
          </div>
        ): <NoItems text={getQuote()}/>,
    },
  ];

  return testOrder?.id?(
    <div>
      <BreadCrumbs routes={breadCrumb} />
      <div className="test-det-header-root">
        <Header
          text={`Test for ${project?.name} v${testOrder?.version?.number}`}
          size={3}
        />
        <div style={{ display: "flex" }}>
          <div
            className="edit-test-btn-container mr-4"
            onClick={() => {
              setShowEditTest(true);
            }}
          >
            <PencilAltIcon className="h-6 w-7" />
            <p>Update Info</p>
          </div>
        </div>
      </div>

      <div>
        <div className="test-detail-gen-info-root mt-6">
          <div>
            <span className="mr-2">Created At: </span>
            <span>{humanReadableDate(testOrder?.created_at)}</span>
          </div>
          <div>
            <span className="mr-2">Deadline: </span>
            <span>{humanReadableDate(testOrder?.deadline)}</span>
          </div>
          <div>
            <span className="mr-2">Status: </span>
            <ProjectStatus status={testOrder?.status} />
          </div>
          <div>
            <span className="mr-2">Source URL:</span>
            <span className="link" onClick={() => goTo(getSourceFile())}>
              v{testOrder?.version?.number}
            </span>
          </div>
          <div>
            <span className="mr-2">Total Features:</span>
            <span
              style={{ color: "var(--app-theme-success)", fontWeight: "bold" }}
            >
              {testOrder?.features?.length || 0}
            </span>
          </div>
        </div>
        <div className="mt-10">
          <TabView
            data={TABS}
            contentAreaStyle={{ paddingTop: 30 }}
            onMount={(changer) => setChanger(changer)}
          />
        </div>
      </div>

      {showAddReview && (
        <PopModal
          close={() => setShowAddReview(false)}
          title="Review Form"
          size={"medium"}
          children={
            <ProjectReviewForm
              review={selectedReview}
              reviews={testOrder?.reviews || []}
              updateTest={setTestOrder}
              testOrder={testOrder}
              close={() => setShowAddReview(false)}
            />
          }
        />
      )}

      {showEditTest && (
        <PopModal
          close={() => setShowEditTest(false)}
          title="Update Test order form"
          size={"medium"}
          children={
            <TestRequestForm
              toEdit={testOrder}
              handleCreateTestOrder={handleUpdateTestOrder}
            />
          }
        />
      )}
    </div>
  ): <ActivityIndicator />;
}
