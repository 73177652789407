import { GET_COMPANY_AGENT_INFO, LOAD_ADMIN_COMPANIES, LOAD_ALL_PROJECTS, LOAD_COMPANY_AGENTS, LOAD_COMPANY_AGENT_INVITATIONS, LOAD_COMPANY_AUDIT_LOGS, LOAD_COMPANY_BILLS, LOAD_PROJECT_TESTS_FOR_TESTER, SAVE_CURRENT_COMPANY, SAVE_LOGGED_IN_USER_INFO, SET_PREFIX, SHOW_SIDE_PANE } from "../actions/constants";
import {
  CONFIGURE_HOST,
  CREATE_COMPANY,
  CREATE_PROJECT,
  CREATE_PROJECT_VERSION,
  CREATE_USER,
  GET_COMPANY_INFO,
  GET_COMPANY_PROJECT_FEATURES,
  GET_COMPANY_PROJECT_INFO,
  GET_COMPANY_PROJECT_SINGLE_FEATURES,
  GET_COMPANY_USERS,
  GET_PROJECT_INFO,
  GET_SINGLE_COMPANY_PROJECT_INFO,
  GET_TESTED_FEATURES,
  GET_TESTING_AGENT_PROJECTS,
  GET_USER_INFO,
  SAVE_FEATURE,
  SET_COMPANY_INFO,
  SET_USER_INFO,
  UPDATE_COMPANY_INFO,
  UPDATE_PROJECT_INFO,
  UPDATE_USER_INFO,

} from "../constants";

// User Reducers

export const createUserReducer = (state = {}, action) => {
  if (action.type === CREATE_USER) {
    return action.payload;
  }
  return state;
};

export const getUserInfoReducer = (state = { id: null }, action) => {
  if (action.type === GET_USER_INFO) {
    return action.payload;
  }
  return state;
};

export const setUserInfoReducer = (state = {}, action) => {
  if (action.type === SET_USER_INFO) {
    return action.payload;
  }
  return state;
};

export const updateUserInfoReducer = (state = {}, action) => {
  if (action.type === UPDATE_USER_INFO) {
    return action.payload;
  }
  return state;
};

//Company Reducers

export const createCompanyReducer = (
  state = { companyName: "", companyType: "", companyProfilePic: {} },
  action
) => {
  if (action.type === CREATE_COMPANY) {
    return action.payload;
  }
  return state;
};
export const getCompanyInfoReducer = (state = {}, action) => {
  if (action.type === GET_COMPANY_INFO) {
    return action.payload;
  }
  return state;
};

export const setCompanyInfoReducer = (state = {}, action) => {
  if (action.type === SET_COMPANY_INFO) {
    return action.payload;
  }
  return state;
};

export const updateCompanyInfoReducer = (state = {}, action) => {
  if (action.type === UPDATE_COMPANY_INFO) {
    return action.payload;
  }
  return state;
};

// Project Reducers

export const createProjectReducer = (state = {}, action) => {
  if (action.type === CREATE_PROJECT) {
    return action.payload;
  }
  return state;
};

//Create project version reducer
export const createProjectVersionReducer = (state = {}, action) => {
  if (action.type === CREATE_PROJECT_VERSION) {
    return action.payload;
  }

  return state;
};

export const getProjectInfoReducer = (state = {}, action) => {
  if (action.type === GET_PROJECT_INFO) {
    return action.payload;
  }

  return state;
};

export const updateProjectInfoReducer = (state = {}, action) => {
  if (action.type === UPDATE_PROJECT_INFO) {
    return action.payload;
  }
  return state;
};

//get projects belonging to a specific category of users

export const getCompanyProjectReducer = (state = null, action) => {
  if (action.type === GET_COMPANY_PROJECT_INFO) {
    return action.payload;
  }

  return state;
};

export const getSingleCompanyProject = (state = null, action) => {
  if (action.type === GET_SINGLE_COMPANY_PROJECT_INFO) {
    return action.payload;
  }

  return state;
};

// Get company users
export const getCompanyUsersReducer = (state = null, action) => {
  if (action.type === GET_COMPANY_USERS) {
    return action.payload;
  }

  return state;
};

//get company project features
export const getCompanyProjectFeaturesReducer = (state = null, action) => {
  if (action.type === GET_COMPANY_PROJECT_FEATURES) {
    return action.payload;
  }

  return state;
};

//get company single project features
export const getCompanyProjectSingleFeature = (state = null, action) => {
  if (action.type === GET_COMPANY_PROJECT_SINGLE_FEATURES) {
    return action.payload;
  }

  return state;
};

//testing agent projects
export const getTestingAgentProjectsReducer = (state = null, action) => {
  if (action.type === GET_TESTING_AGENT_PROJECTS) {
    return action.payload;
  }

  return state;
};

// get all tested features
export const getTestedFeaturesReducer = (state = null, action) => {
  if (action.type === GET_TESTED_FEATURES) {
    return action.payload;
  }

  return state;
};

//save tested feature
export const saveFeatureTestReducer = (state = {}, action) => {
  if (action.type === SAVE_FEATURE) {
    return action.payload;
  }

  return state;
};

// host configure reducer
export const configureHostReducer = (state = "localhost", action) => {
  if (action.type === CONFIGURE_HOST) {
    return action.payload;
  }

  return state;
};

export const toggleSidePaneReducer = (
  state = { on: false, component: null },
  action
) => {
  if (action.type === SHOW_SIDE_PANE) return action.payload || state;
  return state;
};

export const saveUserInfoReducer = (state = {}, action) => {
  if (action.type === SAVE_LOGGED_IN_USER_INFO) {
    return action.payload;
  }
  return state;
};
export const saveCompanyAdminInfoReducer = (state = {}, action) => {
  if (action.type === GET_COMPANY_AGENT_INFO) {
    return action.payload;
  }
  return state;
};
export const saveCurrentCompanyReducer = (state = {}, action) => {
  if (action.type === SAVE_CURRENT_COMPANY) {
    return action.payload;
  }
  return state;
};
export const fetchAllProjectsReducer= (state = [], action) => {
  if (action.type === LOAD_ALL_PROJECTS) {
    return action.payload;
  }
  return state;
};
export const fetchAllCompanyAgentsReducer= (state = [], action) => {
  if (action.type === LOAD_COMPANY_AGENTS) {
    return action.payload;
  }
  return state;
};
export const fetchAllCompanyAgentInvitationsReducer= (state = [], action) => {
  if (action.type === LOAD_COMPANY_AGENT_INVITATIONS) {
    return action.payload;
  }
  return state;
};
export const setPrefixReducer= (state = "", action) => {
  if (action.type === SET_PREFIX) {
    return action.payload;
  }
  return state;
};

export const fetchAllTestersProjectTestReducer= (state = [], action) => {
  if (action.type === LOAD_PROJECT_TESTS_FOR_TESTER) {
    return action.payload;
  }
  return state;
};
export const fetchAllCompanyBillsReducer= (state = [], action) => {
  if (action.type === LOAD_COMPANY_BILLS) {
    return action.payload;
  }
  return state;
};
export const fetchAllCompanyAuditLogsReducer= (state = [], action) => {
  if (action.type === LOAD_COMPANY_AUDIT_LOGS) {
    return action.payload;
  }
  return state;
};
export const fetchAdminCompaniesReducer= (state = [], action) => {
  if (action.type === LOAD_ADMIN_COMPANIES) {
    return action.payload;
  }
  return state;
};