import { combineReducers } from "redux";
import {
  toggleSidePaneReducer,
  saveUserInfoReducer,
  fetchAllProjectsReducer,
  saveCurrentCompanyReducer,
  saveCompanyAdminInfoReducer,
  fetchAllCompanyAgentsReducer,
  fetchAllCompanyAgentInvitationsReducer,
  setPrefixReducer,
  fetchAllTestersProjectTestReducer,
  fetchAllCompanyBillsReducer,
  fetchAllCompanyAuditLogsReducer,
  fetchAdminCompaniesReducer,
} from "./reducers";

const parentReducer = combineReducers({
  sidePane: toggleSidePaneReducer,
  user: saveUserInfoReducer,
  projects: fetchAllProjectsReducer,
  company: saveCurrentCompanyReducer,
  admin: saveCompanyAdminInfoReducer,
  admins: fetchAllCompanyAgentsReducer,
  invitations: fetchAllCompanyAgentInvitationsReducer,
  prefix:setPrefixReducer,
  testFeatures:fetchAllTestersProjectTestReducer,
  bills:fetchAllCompanyBillsReducer,
  logs:fetchAllCompanyAuditLogsReducer,
  companies:fetchAdminCompaniesReducer
});

export default parentReducer;
