import React, { useState } from "react";
import { NoItems, Table } from "@kehillahglobal/ui";
import { PlusIcon } from "@heroicons/react/outline";
import PopModal from "../../../SharedComponents/popModal/PopModal";
import { useDispatch, useSelector } from "react-redux";
import TableActions from "../../../SharedComponents/actions/TableActions";
import { getQuote } from "../../../utils/Quotes";
import { humanReadableDate, isEmpty } from "../../../utils/utils";
import CompanyAdminInvitationForm from "./CompanyAdminInvitationForm";
import { OWNER } from "../../../utils/constant";
import ConfirmationModal from "../../../SharedComponents/popModal/ConfirmationModal";
import { toast } from "react-toastify";
import { DELETE_COMPANY_ADMIN } from "../../../utils/urls";
import { updateAdminsLocallyAction } from "../../../store/actions";
import { formApiCall } from "../../../utils/api";

function CompanyAdmins({setChanger}) {
  const admins = useSelector((state) => state.admins);
  const loggedInAdmin = useSelector((state) => state.admin);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [activeID, setActiveID] = useState("");
  const dispatch = useDispatch()
  // eslint-disable-next-line
  const [selectedAdmin, setSelectedAdmin] = useState({});
  // eslint-disable-next-line
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      setActiveID("");
      setShowActions(false);
    } else {
      setActiveID(item?.id);
      setShowActions(true);
    }
  };

  const handleDeleteAdmin = ()=>{
    formApiCall(DELETE_COMPANY_ADMIN, {
      agent_id: selectedAdmin?.id,
    }).then((res) => {
      setSelectedAdmin({});
      if (res?.success) {
        toast.success("Admin successfully removed");
        let filtered = admins?.filter((item) => item?.id !== selectedAdmin?.id);
        dispatch(updateAdminsLocallyAction(filtered));
      } else {
        toast.error(res?.error);
      }
    });
  }

  const prepareTableData = () => {
    return admins.map((admin, index) => [
      <p>{index + 1}</p>,
      <p>{humanReadableDate(admin?.created_at)}</p>,
      <p>{admin?.user?.name}</p>,
      <p>{admin.user?.email}</p>,
      <p>{admin?.role}</p>,
      loggedInAdmin?.role===OWNER && 
      <TableActions
        onDelete={() => {
          setSelectedAdmin(admin);
          setShowDeleteConfirmation(true);
        }}
        // onUpdate={() => {
        //   setShowAddModal(true);
        //   setSelectedAdmin(admin);
        // }}
        item={admin}
        active={activeID}
        toggleShowMoreOptions={toggleShowMoreOptions}
        show={showActions}
      />,
    ]);
  };
  return (
    <>
      <div>
        <div
          className="add-item-container"
          onClick={() => {
            setSelectedAdmin({});
            setShowAddModal(true);
          }}
        >
          <PlusIcon className="h-6 w-7" />
          <p>Invite An Admin</p>
        </div>
        {isEmpty(admins) ? (
          <NoItems text={getQuote()} />
        ) : (
          <Table
            columns={[
              "#",
              "Created At",
              "Name",
              "Email",
              "Role",
              loggedInAdmin?.role === OWNER && "Actions",
            ]}
            data={prepareTableData()}
            hoverAnimation={false}
          />
        )}
        {showAddModal && (
          <PopModal
            close={() => setShowAddModal(false)}
            title="Company Agent Invitation Form"
            size={"medium"}
            children={
              <CompanyAdminInvitationForm
                close={() => setShowAddModal(false)}
                toggleShowMoreOptions={toggleShowMoreOptions}
                setChanger={setChanger}
              />
            }
          />
        )}

        {showDeleteConfirmation && (
          <ConfirmationModal
            close={() => setShowDeleteConfirmation(false)}
            msg={`Are you sure you want to remove ${selectedAdmin?.user?.name} from your company ? `}
            onDelete={() => handleDeleteAdmin()}
          />
        )}
      </div>
    </>
  );
}

export default CompanyAdmins;
