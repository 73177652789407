import { TabView } from "@kehillahglobal/ui";
import React, { useState } from "react";
import CompanyAdmins from "./CompanyAdmins";
import InvitedAdmins from "./InvitedAdmins";

export default function CompanyAdminWrapper() {
  // eslint-disable-next-line
  const [changer, setChanger] = useState("list-admins");
  const TABS = [
    {
      name: "Admins",
      id: "list-admins",
      component: (
        <CompanyAdmins setChanger={() => setChanger("invite-admin")} />
      ),
    },
    {
      name: "Invitations",
      id: "invite-admin",
      component: <InvitedAdmins />,
    },
  ];
  return (
    <div>
      <h1 className="font-bold text-4xl tracking-tighter mb-10">
        Company Admins
      </h1>
      <div>
        <TabView
          data={TABS}
          contentAreaStyle={{ paddingTop: 30 }}
          onMount={(changer) => setChanger(changer)}
        />
      </div>
    </div>
  );
}
