import {
  CashIcon,
  CogIcon,
  DatabaseIcon,
  DocumentSearchIcon,
  FolderIcon,
  HomeIcon,
  LogoutIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import Money from "../icons/Money";

const prefix  = window.location.href.split("/")
let PREFIX = prefix[3]
export const CADMIN_MENU = [
  {
    groupName: "Manage",
    children: [
      {
        id: "dashboard",
        name: "Dashboard",
        link: `/${PREFIX}/dashboard`,
        icon: <DatabaseIcon className="h-6 w-7" />,
      },
      {
        id: "projects",
        name: "Projects",
        link: `/${PREFIX}/projects`,
        icon: <FolderIcon className="h-6 w-7" />,
      },
      {
        id: "bills",
        name: "Bills",
        link: `/${PREFIX}/bills`,
        icon: <CashIcon className="h-6 w-7" />,
      },
      {
        id: "audit-logs",
        name: "Audit Logs",
        link: `/${PREFIX}/audit/logs`,
        icon: <DocumentSearchIcon className="h-6 w-7" />,
      },
    ],
  },
];
export const TESTER_MENU = [
  {
    groupName: "Manage",
    children: [
      {
        id: "home",
        name: "Home",
        link: `/${PREFIX}/home`,
        icon: <HomeIcon className="h-6 w-7" />,
      },
      {
        id: "Tests",
        name: "Tests",
        link: `/${PREFIX}/tests`,
        icon: <FolderIcon className="h-6 w-7" />,
      },
      {
        id: "Payments",
        name: "Payments",
        link: `/${PREFIX}/payments`,
        icon: <Money />,
      },
    ],
  },
];
export const CADMIN_BOTTOM_MENU = [
  {
    id: "Agents",
    name: "Agents",
    icon: <UsersIcon className="h-6 w-7" />,
    link: `/${PREFIX}/agents`,
  },
  {
    id: "profile",
    name: "Profile",
    icon: <UserIcon className="h-6 w-7" />,
    link: `/${PREFIX}/admin/profile`,
  },
  {
    id: "settings",
    name: "Settings",
    icon: <CogIcon className="h-6 w-7" />,
    link: `/${PREFIX}/settings`,
  },
  {
    id: "sign-out",
    name: "Sign Out",
    icon: <LogoutIcon className="h-6 w-7" />,
  },
];
export const TESTER_BOTTOM_MENU = [
  {
    id: "profile",
    name: "Profile",
    icon: <UserIcon className="h-6 w-7" />,
    link: `/${PREFIX}/profile`,
  },
  {
    id: "sign-out",
    name: "Sign Out",
    icon: <LogoutIcon className="h-6 w-7" />,
  },
];
