import { ChatAltIcon } from "@heroicons/react/outline";
import { CheckIcon, UserGroupIcon } from "@heroicons/react/solid";
import { FormGenerator, NoItems, Table } from "@kehillahglobal/ui";
import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import { Pagination } from "../../../../../SharedComponents";
import TableActions from "../../../../../SharedComponents/actions/TableActions";
import AddButton from "../../../../../SharedComponents/addButton";
import ActivityIndicator from "../../../../../SharedComponents/Loader.js/ActivityIndicator";
import ConfirmationModal from "../../../../../SharedComponents/popModal/ConfirmationModal";
import PopModal from "../../../../../SharedComponents/popModal/PopModal";
import SearchBar from "../../../../../SharedComponents/SearchBar";

import { formApiCall } from "../../../../../utils/api";
import { COMPLETED } from "../../../../../utils/constant";
import { getQuote } from "../../../../../utils/Quotes";
import {
  BASE_URL,
  CREATE_TEST_FEATURE,
  DELETE_TEST_FEATURE,
  UPDATE_TEST_FEATURE,
} from "../../../../../utils/urls";
import { goTo, isEmpty } from "../../../../../utils/utils";

export default function ProjectTestFeatures({ testOrder, setTestOrder }) {
  const [showActions, setShowActions] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showTestNotes, setShowTestNotes] = useState(false);
  const [activeID, setActiveID] = useState("");
  const [itemsDisplayed, setItemsDisplayed] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [features, setFeatures] = useState(null);

  useEffect(() => {
    setFeatures(testOrder?.features);
  }, [testOrder?.features]);

  const toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      setActiveID("");
      setShowActions(false);
    } else {
      setActiveID(item?.id);
      setShowActions(true);
    }
  };

  const handleFeatureDelete = () => {
    formApiCall(DELETE_TEST_FEATURE, {
      test_feature_id: selectedFeature?.id,
    }).then((res) => {
      if (res?.success) {
        let { features } = testOrder;
        let filtered = features?.filter(
          (item) => item?.id !== selectedFeature?.id
        );
        testOrder.features = filtered;
        setTestOrder(testOrder);
        toggleShowMoreOptions();
        setShowDeleteModal(false);
        toast.success("Feature successfully deleted.");
      } else {
        toast.error("An error occurred. Please try again");
      }
    });
  };

  const handleCreateFeature = (data, reset) => {
    let toSend = selectedFeature?.id
      ? {
          name: data?.name,
          id: selectedFeature?.id,
        }
      : {
          ...data,
          test_order_id: testOrder?.id,
          testing_sheet: data?.testing_sheet?.data?.file?.data,
        };

    formApiCall(selectedFeature?.id ? UPDATE_TEST_FEATURE :CREATE_TEST_FEATURE, toSend).then((res) => {
      if (res?.success) {
        let features = [];
        if (selectedFeature?.id) {
          let filtered = testOrder?.features?.filter(
            (item) => item?.id !== res?.data?.id
          );
          features = [res?.data, ...filtered];
        } else {
          if (data?.type === "Multiple") {
            features = res?.data;
          } else {
            let existing = testOrder?.features || [];
            features = [res?.data, ...existing];
          }
        }
        testOrder.features = features;
        setTestOrder(testOrder);
        toggleShowMoreOptions();
        setShowAddModal(false);
        toast.success("Feature successfully added.");
      } else {
        toast.error("An error occurred. Please try again");
      }
    });
  };

  const getTestNotes = (tests) => {
    return (tests || [])
      ?.filter((test) => !isEmpty(test?.note) || !isEmpty(test?.media))
      ?.map((test) => {
        return { note: test?.note, media: test?.media };
      });
  };

  const hasBeenTested = (tests) => {
    if (isEmpty(tests)) return false;
    let failedCheck = tests?.filter((test) =>
      ["Failed", "Passed"].includes(test?.status)
    );
    if (failedCheck?.length) return true;
  };
  const prepareTableData = () => {
    return itemsDisplayed?.map((feature, index) => [
      <div
        className={`test-status ${
          !hasBeenTested(feature?.tests?.tests) && !feature?.tests?.has_failing
            ? "pending-bg"
            : !feature?.tests?.has_failing
            ? "passed-bg"
            : "failed-bg"
        }`}
      >
        {!feature?.tests?.has_failing ? (
          <CheckIcon className="h-6 w-7 passed" />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        )}
      </div>,
      <p>{feature?.name}</p>,
      <div className="flex-me">
        <div className="flex-me-center">
          <UserGroupIcon className="w-4 h-4 mr-1" color="grey" />
          <span>{feature?.tests?.tests?.length || 0}</span>
        </div>
        <div
          className="flex-me-center ml-5 test-notes"
          onClick={() => {
            if (getTestNotes(feature?.tests?.tests)?.length > 0) {
              setSelectedFeature(feature);
              setShowTestNotes(true);
            }
          }}
        >
          <ChatAltIcon className="w-4 h-4 mr-1" />
          <span>{getTestNotes(feature?.tests?.tests)?.length || 0}</span>
        </div>
      </div>,
      <TableActions
        onDelete={() => {
          setSelectedFeature(feature);
          setShowDeleteModal(true);
        }}
        onUpdate={(item) => {
          setSelectedFeature(feature);
          setShowAddModal(true);
        }}
        item={feature}
        active={activeID}
        toggleShowMoreOptions={toggleShowMoreOptions}
        show={showActions}
      />,
    ]);
  };

  const onSearch = (text) => {
    if (!text) return;
    let filtered = testOrder?.features?.filter((feature) => {
      return ["name", "status", "note"].some((item) =>
        feature[item]
          ?.toString()
          ?.toLowerCase()
          ?.includes(text?.toString()?.toLowerCase())
      );
    });
    setFeatures(filtered);
  };

  const renderCreateFeatureForm = () => {
    const newFields = [
      {
        fieldType: FormGenerator.Fields.RADIOGROUP,
        label: "How many features are you adding",
        data: ["Single", "Multiple"],
        value: "",
        name: "type",
        required: true,
        conditionalDisplays: [
          {
            valueToCheck: "Single",
            fields: [
              {
                fieldType: FormGenerator.Fields.INPUT,
                label: "What is the name of this feature ?.",
                value: "",
                name: "name",
                required: true,
              },
            ],
          },
          {
            valueToCheck: "Multiple",
            fields: [
              {
                fieldType: FormGenerator.Fields.FILE,
                label: "Upload Features sheet ",
                placeholder: "upload testing sheet (csv format)",
                name: "testing_sheet",
                required: true,
                accepts: [".csv"],
              },
            ],
          },
        ],
      },
    ];

    const updateField = [
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "What is the name of this feature ?.",
        value: selectedFeature?.name || "",
        name: "name",
        required: true,
      },
    ];
    return (
      <FormGenerator
        title={""}
        subtitle={""}
        onSubmit={(data, reset) => {
          handleCreateFeature(data, reset);
        }}
        formSubmitBtnText={selectedFeature?.id ? "Save changes" : "Add Feature"}
        elevation={0}
        fields={selectedFeature?.id ? updateField : newFields}
      />
    );
  };
  return testOrder?.id ? (
    <div>
      <div className="export-results-btn-container">
        <AddButton
          text={"New Feature"}
          onClick={() => {
            setShowAddModal(true);
            setSelectedFeature({});
          }}
        />
        {testOrder?.status?.toLowerCase() === COMPLETED?.toLowerCase() && (
          <div
            className="view-results-container"
            onClick={() => {
              let redirectTo = `${BASE_URL}/test.order.results.view/${testOrder?.id}`;
              window.open(redirectTo, "_blank");
            }}
          >
            <p>View Full Results</p>
          </div>
        )}
      </div>

      <div className="mb-5">
        <SearchBar
          placeholder={"Search for a feature"}
          onSearch={(text) => onSearch(text)}
        />
      </div>
      {isEmpty(features) ? (
        <NoItems text={getQuote()} />
      ) : (
        <>
          <Table
            columns={["", "Feature", "Status", "Actions"]}
            data={prepareTableData()}
            hoverAnimation={false}
            tableClass={"project-features-table"}
          />
          <div className="mt-7">
            <Pagination
              noOfItems={features?.length}
              items={features}
              setItemsDisplayed={setItemsDisplayed}
            />
          </div>
        </>
      )}

      {showAddModal && (
        <PopModal
          close={() => setShowAddModal(false)}
          title="Feature Form"
          size={"medium"}
          children={renderCreateFeatureForm()}
        />
      )}
      {showTestNotes && (
        <PopModal
          close={() => setShowTestNotes(false)}
          title="Test Notes"
          size={"medium"}
          children={
            <div className="test-note-main-wrapper">
              {getTestNotes(selectedFeature?.tests?.tests).map(
                ({ note, media }, index) => {
                  return (
                    <div className="px-4 py-2 rounded-lg inline-block bg-gray-300 text-gray-600 mb-5">
                      <p className="text-bold">Tester {index + 1}</p>
                      <span className="">{note}</span>
                      <div className="mt-3">
                        {media?.map((shot) => (
                          <div className="mb-4 shot-note">
                            <img
                              src={shot?.file?.file}
                              alt=" "
                              onClick={() => goTo(shot?.file?.file)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                }
                
                
              )}
            </div>
          }
        />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          close={() => setShowDeleteModal(false)}
          msg={`You're about to delete "${selectedFeature?.name}" feature. This operation can not be undone.`}
          onDelete={() => handleFeatureDelete()}
        />
      )}
    </div>
  ) : (
    <ActivityIndicator />
  );
}
