import React, { useEffect, useState } from "react";
import "@kehillahglobal/ui/dist/index.css";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "./TestingAgentProfile.css";
import { PencilIcon } from "@heroicons/react/outline";
import { CheckboxGroup, Textbox } from "@kehillahglobal/ui";
import { fileUploader, parseJSON } from "../../../utils/utils";
import Button from "../../../SharedComponents/Button/Button";
import { formApiCall } from "../../../utils/api";
import { UPDATE_USER_INFO } from "../../../utils/urls";
import Loader from "../../../SharedComponents/Loader.js/Loader";
import { saveUserInfoAction } from "../../../store/actions";
import { placeholderImage } from "../../../utils/constant";
function TestingAgentProfile() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  let [loading, setLoading] = useState(false);
  let [image, setImage] = useState(user?.image?.file || "");

  let [full_name, setFull_name] = useState(user?.full_name || "");
  let [username, setUsername] = useState(user?.username || "");
  let [devices, setDevices] = useState([]);

  useEffect(() => {
    let devices = parseJSON(user?.info)?.devices;
    setDevices(devices || []);
  }, [user?.info]);

  const renderEditForm = () => {
    return (
      <div className="mt-10">
        <div className="grid-repeat-2">
          <div>
            <label class="block text-gray-700 text-sm font-bold" for="username">
              Full Name
            </label>
            <Textbox
              value={full_name}
              onChange={(e) => setFull_name(e.target.value)}
            />
          </div>
          <div>
            <label class="block text-gray-700 text-sm font-bold" for="username">
              Username
            </label>
            <Textbox
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-6">
          <label class="block text-gray-700 text-sm font-bold" for="username">
            Devices
          </label>
          <CheckboxGroup
            data={[
              "Desktop or Laptop",
              "Android Phone",
              "Android Tablet",
              "Iphone",
              "Ipad",
            ]}
            defaultValue={devices}
            onItemSelected={(arr, val) => setDevices(arr)}
          />
        </div>
        <div className="mt-10 flex-right">
          <Button
            name={"Save Changes"}
            fill={true}
            onClick={() => handleUpdateUserInfo()}
          />
        </div>
      </div>
    );
  };

  const handleSelectProfile = () => {
    let fileInput = document.getElementById("image-input");
    fileInput.click();
    fileInput.onchange = async (e) => {
      setLoading(true);
      let image = await fileUploader(e.target.files[0]);
      if (image?.id) {
        formApiCall(UPDATE_USER_INFO, {
          id: user?.id,
          image_id: image?.id,
        }).then((res) => {
          setLoading(false);
          if (res?.success) {
            dispatch(saveUserInfoAction(res?.data));
            setImage(res?.data?.image?.file);
            toast.success("profile image updated successfully");
          } else {
            toast.error("An error occurred. Please try again");
          }
        });
      } else {
        setLoading(false);
        toast.error("An error occurred. Please try again");
      }
    };
  };
  const handleUpdateUserInfo = () => {
    let info = parseJSON(user?.info);
    let data = {
      full_name,
      username,
      info: JSON.stringify({
        ...info,
        devices,
      }),
    };
    setLoading(true);
    formApiCall(UPDATE_USER_INFO, {
      id: user?.id,
      ...data,
    }).then((res) => {
      setLoading(false);

      if (res?.success) {
        toast.success("updated successfully");
        dispatch(saveUserInfoAction(res?.data));
      } else {
        toast.error("An error occurred. Please try again");
      }
    });
  };

  return (
    <>
      <div className="md:px-10 px-10 py-10">
        <div className="profile-wrapper">
          <div className="profile-image-container">
            <input
              type={"file"}
              id="image-input"
              className="hide"
              accept="image/*"
            />
            <img src={image || placeholderImage} alt="  " />
            <div
              className="change-profile-container"
              onClick={() => handleSelectProfile()}
            >
              <PencilIcon className="h-6 w-7" />
            </div>
          </div>
          <div className="ml-5">
            <h3 className="font-bold text-3xl tracking-tighter">
              {user?.full_name}
            </h3>
            <p className="tracking-tighter mb-3">
              {user?.username && "@" + user?.username}
            </p>
            <span>{user?.email}</span>
          </div>
        </div>
        {renderEditForm()}
      </div>

      {loading && <Loader text={"processing.."} />}
    </>
  );
}

export default TestingAgentProfile;
