import { store } from "../store/store";
import { v4 as UUID4 } from 'uuid';
import { formApiCall } from "./api";
import { UPLOAD_MEDIA } from "./urls";



export const getActiveUserID = () => {
  const userID = store.getState().user?.id;
  return userID || null;
};

export const getActiveCompanyID = () => {
  return store.getState().companyInfo?.id || null;
};

export const getCompanyProjects = () => {
  return store.getState().companyProject || null;
};


export const createImageUrl = async(file) => {
    
     const filename = `${UUID4()}${Date.now().toString()}${file.name}`.replace(/^(?!.*['.jpg', '.png', '.jpeg']).*[\W_]+/g,"")
     const Image = new File([file], filename)
     return Image
  };


  export const reProcessImage = (profile) => {
    const im = `${profile}`.split('/')
    const im1 = `${im[0]}/user_profile_pics/`

    im[0] = im1
    return im.join("")      
 }
 export const isEmpty = (value) => {
   if (
     value === undefined ||
     value === null ||
     value === "" ||
     value?.length===0 ||
     value === "null" ||
     value === "undefined" 
   )
     return true;
   return false;
 };

 export const sanitizeValue = (val) => {
   if (isEmpty(val)) {
     return null;
   }

   return val;
 };

 /**
 * Returns a Javascript object from a string
 * @param {*} val
 * @returns
 */
export const parseJSON = (val) => {
  if(typeof val ==="object") return val;
  const sanitizedValue = sanitizeValue("" + val);
  if (!sanitizedValue) return null;
  try {
    return JSON.parse(sanitizedValue);
  } catch (error) {
    console.log(`JSON: Error parsing ${val} to JSON`);
    return {};
  }
};


export const goTo = (link)=>{
  if(!link) return
  window.open(link, "_blank")
}

export const humanReadableDate = (date,time=false)=>{
  if(!date) return ""
    let newDate = new Date(date);
    let options
    options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    if(time){
      options = {
         year: "numeric",
         month: "short",
         day: "numeric",
         hour: "numeric",
         minute: "numeric",
       };
    }

    return Intl.DateTimeFormat("en-US", options).format(newDate);
}



export const shortenMe = (number) => {
  var pow = Math.pow,
    floor = Math.floor,
    abs = Math.abs,
    log = Math.log;
  var abbrev = [" K", "M", " B", "T"];

  function round(n, precision) {
    var prec = Math.pow(10, precision);
    return Math.round(n * prec) / prec;
  }

  var base = floor(log(abs(number)) / log(1000));
  var suffix = abbrev[Math.min(2, base - 1)];
  base = abbrev.indexOf(suffix) + 1;
  return suffix ? round(number / pow(1000, base), 2) + suffix : "" + number;
};

export const capitalizeMe = (text) => {
  if (!text) return "";
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

// file uploader

export const fileUploader = async(file)=>{
let media  = await formApiCall(UPLOAD_MEDIA, {file:file})
return media?.data
}