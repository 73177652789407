import { SearchIcon } from "@heroicons/react/outline";
import React from "react";

import "./searchbar.css";

export default function SearchBar({ placeholder, onSearch }) {
  return (
    <div className="search-bar-container">
      <SearchIcon className="h-6 w-7 search-bar-icon" />
      <input
        type={"text"}
        className="search-input"
        placeholder={placeholder}
        onChange={(e) => onSearch && onSearch(e.target.value)}
      />
    </div>
  );
}
