import { NoItems, Table } from "@kehillahglobal/ui";
import React from "react";
import { useSelector } from "react-redux";
import ProjectStatus from "../../../SharedComponents/ProjectInfo/components/ProjectStatus";
import { getQuote } from "../../../utils/Quotes";
import { humanReadableDate, isEmpty, shortenMe } from "../../../utils/utils";

function TesterPayment() {
  const bills = useSelector((state) => state.bills);

  const prepareTableData = () => {
    return bills.map((bill, index) => [
      <p>{index + 1}</p>,
      <p>{humanReadableDate(bill?.created_at)}</p>,
      <p>{bill?.project}</p>,
      <p>v{bill?.version}</p>,
      <p>{shortenMe(bill?.amount||0)}</p>,
      <ProjectStatus status={bill?.is_paid ? "Paid" : "Pending"} />,
    ]);
  };

  return (
    <>
      <h1 className="font-bold text-4xl tracking-tighter mb-10">Payment History</h1>
      <div>
        {isEmpty(bills) ? (
          <NoItems text={getQuote()} />
        ) : (
          <Table
            columns={[
              "#",
              "Created At",
              "Project",
              "Order Version",
              "Amount",
              "Status",
            ]}
            data={prepareTableData()}
            hoverAnimation={false}
          />
        )}
      </div>

    </>
  );
}

export default TesterPayment;

