import { FormGenerator } from "@kehillahglobal/ui";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveUserInfoAction, setPrefixAction } from "../../../store/actions";
import { formApiCall } from "../../../utils/api";
import { SIGNUP_AS_TESTER, TESTER_PREFIX } from "../../../utils/constant";
import { UPDATE_USER_INFO } from "../../../utils/urls";

export default function CreateTester(props) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch()

  useEffect(() => {
    if(user?.is_testing_agent){
        return (window.location.href = "/" + TESTER_PREFIX + "/home");
    }
  }, [user])
  

  const handleUpdateUserInfo = (data, reset) => {
    let toSend = {
      id: user?.id,
      full_name:data?.full_name,
      info:JSON.stringify({
        devices:data?.devices,
      }),
      is_testing_agent:"True"
    };
    formApiCall(UPDATE_USER_INFO, toSend).then((res) => {
      localStorage.removeItem(SIGNUP_AS_TESTER)
      if (res?.success) {
        dispatch(saveUserInfoAction(res?.data))
        dispatch(setPrefixAction(TESTER_PREFIX));
             localStorage.setItem("prefix", TESTER_PREFIX);
        return (window.location.href = "/" + TESTER_PREFIX + "/home");
      }
    });
  };

  return (
    <div className="create-company-root">
      <div className="elevate-float create-form-wrapper">
        
        <center className="mt-5">
          <div className="font-black text-5xl">
            Testable<span style={{ color: "var(--app-accent-pink)" }}>HQ</span>
          </div>
        </center>
        <FormGenerator
          title=""
          subtitle=""
          elevation={0}
          onSubmit={(data, reset) => handleUpdateUserInfo(data, reset)}
          formSubmitBtnText="Apply For Tester"
          fields={[
            {
              label: "Personal",
              fieldType: FormGenerator.Fields.SECTION,
              children: [
                {
                  fieldType: FormGenerator.Fields.INPUT,
                  label: "Full name",
                  placeholder: "John Doe",
                  name: "full_name",
                  value: user?.full_name,
                  required: true,
                },
                {
                  fieldType: FormGenerator.Fields.DROPDOWN,
                  data: ["Ghana", "USA", "Canada"],
                  label: "Country",
                  placeholder: "select Country",
                  name: "country",
                  required: true,
                  value: "",
                },
              ],
            },
            {
              label: "Devices",
              fieldType: FormGenerator.Fields.SECTION,
              children: [
                {
                  fieldType: FormGenerator.Fields.CHECKBOXGROUP,
                  data: [
                    "Desktop or Laptop",
                    "Android Phone",
                    "Android Tablet",
                    "Iphone",
                    "Ipad",
                  ],
                  label: "Devices available for testing",
                  name: "devices",
                  required: true,
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
}
