import { Sidebar } from "@kehillahglobal/ui";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { formApiCall } from "../../utils/api";
import { COMPANY_PREFIX, dummyCompanyImage, TESTER_PREFIX } from "../../utils/constant";
import { LOGOUT, SET_COMPANY_ID_TO_COOKIE } from "../../utils/urls";
import Loader from "../Loader.js/Loader";
import Navbar from "../navbar/Navbar";
import "./PageWrapper.css";
import {
  CADMIN_BOTTOM_MENU,
  CADMIN_MENU,
  TESTER_BOTTOM_MENU,
  TESTER_MENU,
} from "./values";

const SHRINK_SIDE_RATIO = 71;
const EXPANDED_SIDE_RATIO = 271;
function PageWrapper(props) {
  const sidePane = useSelector((state) => state.sidePane);
  const user = useSelector((state) => state.user);
  const admin = useSelector((state) => state.admin);
  const prefix = useSelector((state) => state.prefix);
  const companies = useSelector((state) => state.companies);
  const [sideRatio, setSideRatio] = useState(EXPANDED_SIDE_RATIO);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSidebarStateChange = (state) => {
    if (!state.shrink && sideRatio !== EXPANDED_SIDE_RATIO)
      setSideRatio(EXPANDED_SIDE_RATIO);
    else if (state.shrink && sideRatio !== SHRINK_SIDE_RATIO)
      setSideRatio(SHRINK_SIDE_RATIO);
  };

  const userInfo = {
    userName: user?.full_name,
    role: admin?.role,
    companyName: admin?.company?.name,
    userImage: user?.image?.file,
    companyLogo: admin?.company?.logo?.file || dummyCompanyImage,
  };

  const accounts = (companies || []).map((item) => {
      return {
        ...item,
        logo: item?.company?.logo || dummyCompanyImage,
        name: item?.company?.name,
      };
  });

  const onSwitchAccount = (item) => {
    setIsLoading(true);
    formApiCall(SET_COMPANY_ID_TO_COOKIE, {
      company_id: item?.company?.id,
      company_admin_id: item?.id,
    }).then((res) => {
      setIsLoading(false);
      if (res?.success) {
        localStorage.setItem("prefix", COMPANY_PREFIX);
        return (window.location.href = "/" + COMPANY_PREFIX + "/dashboard");
      } else {
        toast.error("An error occurred. Try again");
      }
    });
  };
  return (
    <div
      className="root-wrapper"
      style={{
        "--side-ratio": `${sideRatio}px`,
      }}
    >
      <div className="sidebar-container">
        <Sidebar
          menu={
            user?.is_testing_agent && prefix === TESTER_PREFIX
              ? TESTER_MENU
              : CADMIN_MENU
          }
          bottomMenu={
            user?.is_testing_agent && prefix === TESTER_PREFIX
              ? TESTER_BOTTOM_MENU
              : CADMIN_BOTTOM_MENU
          }
          accounts={accounts}
          onItemSelect={(item) => {
            const iWantToSwitch = window.confirm(
              `Are you sure you want to switch to ${item?.name} ?`
            );
            if (iWantToSwitch) {
              onSwitchAccount(item);
            }
          }}
          userDetails={userInfo}
          dark={true}
          onTabItemClick={(e, { link }) => {
            if (!link) {
              const iAmSureIWantToLogOut = window.confirm(
                "Are you sure you want to sign out?"
              );
              if (iAmSureIWantToLogOut) {
                formApiCall(LOGOUT, {}).then((res) => {
                  if (res.success) {
                    window.location.href = "/";
                  }
                });
              }
            }
            navigate(link);
          }}
          onShrinkBtnClick={() => setSideRatio(SHRINK_SIDE_RATIO)}
          onStateChange={handleSidebarStateChange}
        />
      </div>
      <div className="children-container">
        {sidePane?.on && (
          <div className="side-pane elevate-float animate-side-pane"></div>
        )}
        <div>
          <Navbar />
        </div>
        <div
          style={{
            width: "93%",
            margin: "auto",
            marginTop: "2rem",
            marginBottom: "5rem",
          }}
        >
          {props.children}
        </div>
      </div>
      {isLoading && <Loader />}
    </div>
  );
}

PageWrapper.defaultProps = {
  sideRatio: SHRINK_SIDE_RATIO,
};
export default PageWrapper;
