import React, { useState } from 'react'
import './CompanyJoinInvitation.css'
import { Button, Textbox } from '@kehillahglobal/ui'
import { ArrowRightIcon } from '@heroicons/react/solid';

function CompanyJoinInvitation() {
  const [code, setCode] = useState("")
  return (
    <>
      <div className="invitation-root">
        <div className="elevate-float invitation-code-form-wrapper">
          <center className="mt-5 mb-10">
            <div className="font-black text-5xl">
              Testable<span className="text-nice-blue">HQ</span>
            </div>
          </center>
          <Textbox placeholder="Enter invitation code" value={code} onChange ={(e)=>setCode(e.target?.value)} />
          <center className="mt-5">
            <Button size="large" theme="success" className="continue-btn">
              <p style={{fontWeight:'bold'}}>Continue</p>
              <ArrowRightIcon className="h-6 w-7 font-bold ml-5" />
            </Button>
          </center>
        </div>
      </div>
    </>
  );
}

export default CompanyJoinInvitation