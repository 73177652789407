import { NoItems, Table } from "@kehillahglobal/ui";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getQuote } from "../../../utils/Quotes";
import { humanReadableDate, isEmpty } from "../../../utils/utils";
import "./TestingAgentTest.css";
function TestingAgentTests() {
  const tests = useSelector((state) => state.testFeatures);
  const prefix = useSelector((state) => state.prefix);
  const navigate = useNavigate();

  const goToDetails = (id) => {
    navigate(`/${prefix}/tests/`+id +"/details" );
  };

const groupBy = (property) => {
  const grouped = tests.reduce(function (acc, obj) {
    var key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
  return grouped;
}


  const prepareTableData = (items) => {
    return Object.values(items).map((values, index) => [
      <p>{index + 1}</p>,
      <p>{humanReadableDate(values[0]?.deadline)}</p>,
      <p onClick={() => goToDetails(values[0].order)}>{values[0]?.company}</p>,
      <p onClick={() => goToDetails(values[0].order)}>{values[0]?.project}</p>,
      <p onClick={() => goToDetails(values[0].order)}>v{values[0]?.version}</p>,
      <p>{values?.length || 0}</p>,
      <div
        className="view-more-btn"
        onClick={() => goToDetails(values[0].order)}
      >
        <p>View info</p>
      </div>,
    ]);
  };

  return (
    <>
      <div>
        <div>
          <h1 className="font-bold text-4xl tracking-tighter mb-10">
            Assigned Tests
          </h1>
        </div>

        <div>
          {isEmpty(tests) ? (
            <NoItems text={getQuote()} />
          ) : (
            <Table
              columns={["#","Deadline", "Company", "Project", "Version", "No. of Features", "Action"]}
              data={prepareTableData(groupBy("order"))}
              hoverAnimation={false}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default TestingAgentTests;
