import React from "react";
import ReactDOM from "react-dom";
export default function ActivityIndicator() {
  return ReactDOM.createPortal(
    <div className="activity-indicator-root">
      <div
        className="hq-circle-loader"
        style={{
          borderTopColor: "fuchsia-900",
          borderBottomColor: "var(--app-accent-pink)",
          borderRightColor: "var(--app-accent-pink)",
          borderLeftColor: "fuchsia-900",
        }}
      ></div>
    </div>,
    document.body
  );
}
