import {
  CREATE_COMPANY,
  CREATE_USER,
  CREATE_PROJECT,
  GET_USER_INFO,
  GET_COMPANY_PROJECT_INFO,
  GET_COMPANY_INFO,
  UPDATE_COMPANY_INFO,
  SET_USER_INFO,
  SET_COMPANY_INFO,

  GET_SINGLE_COMPANY_PROJECT_INFO,
  GET_COMPANY_USERS,
  GET_COMPANY_PROJECT_FEATURES,
  GET_COMPANY_PROJECT_SINGLE_FEATURES,
  CREATE_PROJECT_VERSION,
  GET_TESTING_AGENT_PROJECTS,
  GET_TESTED_FEATURES,
  SAVE_FEATURE,
  CONFIGURE_HOST,
} from "../constants";
import { formApiCall, jsonApiCall } from "../../utils/api";
import {
  CREATE_COMPANY_URL,
  CREATE_PROJECT_URL,
  CREATE_USER_URL,
  GET_COMPANY_INFO_URL,
  GET_COMPANY_PROJECTS_FEATURES_URL,
  GET_COMPANY_PROJECTS_SINGLE_FEATURES_URL,
  GET_COMPANY_PROJECTS_URL,
  GET_USER_INF0_URL,
  UPDATE_COMPANY_INF0_URL,
  UPDATE_USER_INF0_URL,
  GET_COMPANY_USERS_URL,
  GET_SINGLE_COMPANY_PROJECT_URL,
  CREATE_PROJECT_VERSION_URL,
  GET_TESTING_AGENT_PROJECTS_URL,
  GET_TESTED_FEATURES_URL,
  SAVE_FEATURE_URL,
  LIST_ALL_PROJECTS,
  GET_SETUP_DATA,
  GET_COMPANY_ADMIN,
  LIST_COMPANY_AGENTS_OF_COMPANY,
  LIST_COMPANY_AGENT_INVITATIONS,
  LIST_PROJECT_TESTS_FOR_TESTER,
  LIST_COMPANY_BILLS,
  LIST_AUDIT_LOGS,
  LIST_COMPANY_AGENTS,
} from "../../utils/urls";
import { getActiveUserID } from "../../utils/utils";
import { GET_COMPANY_AGENT_INFO, LOAD_ADMIN_COMPANIES, LOAD_ALL_PROJECTS, LOAD_COMPANY_AGENTS, LOAD_COMPANY_AGENT_INVITATIONS, LOAD_COMPANY_AUDIT_LOGS, LOAD_COMPANY_BILLS, LOAD_PROJECT_TESTS_FOR_TESTER, LOAD_SETUP_DATA, SAVE_CURRENT_COMPANY, SAVE_LOGGED_IN_USER_INFO, SET_PREFIX, SHOW_SIDE_PANE } from "./constants";

export const createCompany = (
  payload = { companyName: "", companyType: "", companyProfilePic: {} }
) => {
  return async (dispatch) => {
    const result = await formApiCall(CREATE_COMPANY_URL, payload);

    return dispatch({ type: CREATE_COMPANY, payload: result });
  };
};

export const createUser = (
  payload = { username: "", email: "", profilePic: {} }
) => {
  return async (dispatch) => {
    const result = await formApiCall(CREATE_USER_URL, payload);
    return dispatch({ type: CREATE_USER, payload: result });
  };
};




export const createProject = (payload) => {
  return async (dispatch) => {
    const result = await formApiCall(CREATE_PROJECT_URL, payload);
    return dispatch({ type: CREATE_PROJECT, payload: result });
  };
};


export const createProjectVersion = (payload) => {
  return async(dispatch) => {
    const res = await formApiCall(CREATE_PROJECT_VERSION_URL, payload)

    return dispatch({
      type: CREATE_PROJECT_VERSION,
      payload: res
    })
  }
}


export const getUserInfo = (payload = { id: null }) => {
  return async (dispatch) => {
    const result = await jsonApiCall(GET_USER_INF0_URL, payload);
    return dispatch({
      type: GET_USER_INFO,
      payload: result,
    });
  };
};

export const setUserInfo =
  (payload = { id: null, username: "", email: "", profilePic: {} }) =>
  async (dispatch) =>
    dispatch({
      type: SET_USER_INFO,
      payload,
    });

export const getCompanyInfo = (payload = { id: null }) => {
  return async (dispatch) => {
    const result = await jsonApiCall(GET_COMPANY_INFO_URL, payload);
    return dispatch({ type: GET_COMPANY_INFO, payload: result });
  };
};


export const setCompanyInfo =
  (
    payload = {
      id: null,
      companyName: "",
      companyType: "",
      companyProfilePic: null,
    }
  ) =>
  async (dispatch) =>
    dispatch({ type: SET_COMPANY_INFO, payload });


export const getCompanyUsers = () => {
  const userID = getActiveUserID();

  return async (dispatch) => {
    const result = await formApiCall(GET_COMPANY_USERS_URL, { id: userID });

    return dispatch({ type: GET_COMPANY_USERS, payload: result?.data || [] });
  };
};

export const queryCompanyUsers = (payload) => {
  return { type: GET_COMPANY_USERS, payload: payload };
};



export const getCompanyProjectInfo = () => {
  let id = getActiveUserID();
  return async function (dispatch) {
    const res = await formApiCall(GET_COMPANY_PROJECTS_URL, { id: id });

    return dispatch({
      type: GET_COMPANY_PROJECT_INFO,
      payload: res?.data || [],
    });
  };
};

export const editCompanyInfo = (
  payload = {
    id: null,
    companyName: "",
    companyType: "",
    companyProfilePic: {},
  }
) => {
  return async (dispatch) => {
    const result = await formApiCall(UPDATE_COMPANY_INF0_URL, payload);

    return dispatch({ type: UPDATE_COMPANY_INFO, payload: result });
  };
};

export const editUserInfo = (
  payload = { id: null, username: "", email: "", profilePic: {} }
) => {
  return async (dispatch) => {
    const result = await formApiCall(UPDATE_USER_INF0_URL, payload);

    return dispatch({ type: UPDATE_COMPANY_INFO, payload: result });
  };
};


//search projects query
export const queryCompanyProject = (payload) => {
  return { type: GET_COMPANY_PROJECT_INFO, payload: payload };
};


export const getSingleCompanyProject = (payload) => {
  return async function (dispatch) {
    const res = await formApiCall(GET_SINGLE_COMPANY_PROJECT_URL, payload)

    return dispatch({
      type: GET_SINGLE_COMPANY_PROJECT_INFO,
      payload: res?.data || {}
    })
    
  }
}


//GET COMPANY FEATURES FOR A PARTICULAR PROJECT VERSION
export const getCompanyProjectFeatures = (payload) => {
  return async (dispatch) => {

    const res = await formApiCall(GET_COMPANY_PROJECTS_FEATURES_URL, {
      id: payload,
    });

    return dispatch({
      type: GET_COMPANY_PROJECT_FEATURES,
      payload: res?.data || [],
    });
  };
};

export const getSingleCompanyFeature = (payload) => {
  return async (dispatch) => {
    const res = await formApiCall(GET_COMPANY_PROJECTS_SINGLE_FEATURES_URL, {
      id: payload,
    });

    return dispatch({
      type: GET_COMPANY_PROJECT_SINGLE_FEATURES,
      payload: res?.data || [],
    });
  };
};


// Get Testing agents test assigned projects
export const getTestingAgentProjects = () => {
  let id = getActiveUserID();
  return async dispatch => {
    const res = await formApiCall(GET_TESTING_AGENT_PROJECTS_URL, { id: id})

    return dispatch({
      type: GET_TESTING_AGENT_PROJECTS,
      payload: res?.data || []
    })
  }
}

//search projects query
export const queryTestingAgentProjects = (payload) => {
  return { type: GET_TESTING_AGENT_PROJECTS, payload: payload };
};


//get tested features
export const getTestedFeatures = (payload) => {
  return async (dispatch) => {
    const res = await formApiCall(GET_TESTED_FEATURES_URL, {id: payload})

    return dispatch({
      type: GET_TESTED_FEATURES,
      payload: res?.data || []
    })
  }

}


//save feature
export const saveFeatureTest = (
  payload = { id: "", isPassed: null, comment: "", userId: '' }
) => {
  const user = getActiveUserID()
  return async (dispatch) => {
    const res = await formApiCall(SAVE_FEATURE_URL, {
    id: payload?.id,
    isPassed: payload?.isPassed,
    comment: payload?.comment,
    userId: user
    })

    return dispatch({
      type: SAVE_FEATURE,
      payload: res
    })
  }
}




//configure hosting
export const configureHost = (payload) => {
  return {
    type: CONFIGURE_HOST,
    payload: payload
  }
}


export const toggleSidePane = (load) => {
  return { type: SHOW_SIDE_PANE, payload: load };
};


export const saveUserInfoAction = (info)=>{
  return {type:SAVE_LOGGED_IN_USER_INFO, payload:info}
}


export const saveCurrentCompanyAction = (info)=>{
  return {type:SAVE_CURRENT_COMPANY, payload:info}
}
export const fetchAllProjectsAction = (payload) => {
  return async (dispatch) => {
    const res = await formApiCall(LIST_ALL_PROJECTS, {});
    return dispatch({
      type: LOAD_ALL_PROJECTS,
      payload: res?.data || [],
    });
  };
};
export const fetchAllAgentsAction = () => {
  return async (dispatch) => {
    const res = await formApiCall(LIST_COMPANY_AGENTS_OF_COMPANY, {  });
    return dispatch({
      type: LOAD_COMPANY_AGENTS,
      payload: res?.data || [],
    });
  };
};

export const fetchAllAgentInvitationsAction = () => {
  return async (dispatch) => {
    const res = await formApiCall(LIST_COMPANY_AGENT_INVITATIONS, {  });
    return dispatch({
      type: LOAD_COMPANY_AGENT_INVITATIONS,
      payload: res?.data || [],
    });
  };
};
export const updateProjectListLocallyAction = (payload) => {
    return { type: LOAD_ALL_PROJECTS, payload };
};

export const fetchSetupDataAction = () => {
  return async (dispatch) => {
    const res = await formApiCall(GET_SETUP_DATA, {});
    return dispatch({
      type: LOAD_SETUP_DATA,
      payload: res?.data || {},
    });
  };
};


export const getCompanyAdminInfoAction = () => {
  return async (dispatch) => {
    const res = await formApiCall(GET_COMPANY_ADMIN, {});
    return dispatch({
      type: GET_COMPANY_AGENT_INFO,
      payload: res?.data || {},
    });
  };
};
export const saveCompanyAdminInfoAction = (payload) => {
  return { type: GET_COMPANY_AGENT_INFO, payload };
};
export const updateAdminInvitationsLocallyAction = (payload) => {
  return { type: LOAD_COMPANY_AGENT_INVITATIONS, payload };
};
export const updateAdminsLocallyAction = (payload) => {
  return { type: LOAD_COMPANY_AGENTS, payload };
};
export const setPrefixAction = (prefix) => {
  return { type: SET_PREFIX, payload:prefix };
};
export const fetchAllTestersProjectTestAction = () => {
  return async (dispatch) => {
    const res = await formApiCall(LIST_PROJECT_TESTS_FOR_TESTER, {});
    return dispatch({
      type: LOAD_PROJECT_TESTS_FOR_TESTER,
      payload: res?.data || [],
    });
  };
};
export const fetchAllCompanyBillsAction = () => {
  return async (dispatch) => {
    const res = await formApiCall(LIST_COMPANY_BILLS, {});
    return dispatch({
      type: LOAD_COMPANY_BILLS,
      payload: res?.data || [],
    });
  };
};
export const fetchAllCompanyAuditLogsAction = () => {
  return async (dispatch) => {
    const res = await formApiCall(LIST_AUDIT_LOGS, {});
    return dispatch({
      type: LOAD_COMPANY_AUDIT_LOGS,
      payload: res?.data || [],
    });
  };
};
export const fetchAllAdminCompaniesAction = () => {
  return async (dispatch) => {
    const res = await formApiCall(LIST_COMPANY_AGENTS, {});
    return dispatch({
      type: LOAD_ADMIN_COMPANIES,
      payload: res?.data || [],
    });
  };
};