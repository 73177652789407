import { ChevronRightIcon } from "@heroicons/react/solid";
import { Button, TabView } from "@kehillahglobal/ui";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadCrumbs } from "../../../../SharedComponents";
import Loader from "../../../../SharedComponents/Loader.js/Loader";
import PopModal from "../../../../SharedComponents/popModal/PopModal";
import { formApiCall } from "../../../../utils/api";
import { GET_PROJECT_INFO, ORDER_A_TEST } from "../../../../utils/urls";
import { isEmpty } from "../../../../utils/utils";
import "../index.css";
// import ProjectReviews from "./project-detail-tabs/ProjectReviews";
import ProjectSummary from "./project-detail-tabs/ProjectSummary";
import ProjectTests from "./project-detail-tabs/ProjectTests";

import TestRequestForm from "./TestRequestForm";
export default function ProjectDetail() {
  // eslint-disable-next-line
  const [changer, setChanger] = useState("project-summary");
  const [project, setProject] = useState({});
  const [requestTest, setRequestTest] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true)
    formApiCall(GET_PROJECT_INFO, { project_id: id }).then((res) => {
      setLoading(false)
      if (res?.success) {
        setProject(res?.data);
      }
    });
    setProject(project);
    // eslint-disable-next-line
  }, [id]);

  const breadCrumb = [
    {
      id: 1,
      name: "projects",
      redirect: `/company/projects/`,
    },
    {
      id: 2,
      name: project?.name,
      redirect: `/company/projects/${id}`,
    },
  ];
  const TABS = [
    {
      name: "Test Orders",
      id: "project-versions",
      component: <ProjectTests project={project} setProject={setProject} />,
    },
    {
      name: "Project Summary",
      id: "project-summary",
      component: <ProjectSummary project={project} />,
    },
  ];
  const handleCreateTestOrder = async(data, reset) => {
    let toSend = {
      project_id: project?.id,
      ...data,
      testing_sheet: data.testing_sheet?.data?.file?.data,
      file: data.file?.data?.file?.data,
      number:data?.number[0]
    };
    if (!toSend?.file) {
      delete toSend.file;
    }
    if (!toSend?.testing_sheet) {
      delete toSend.testing_sheet;
    }

    formApiCall(ORDER_A_TEST, toSend).then((res) => {
      if (res?.success) {
        let { tests } = project;
        if (isEmpty(tests)) {
          tests = [res?.data];
        } else {
          tests = [res?.data, ...tests];
        }
        project.tests = tests;
        setProject(project);
        setRequestTest(false);
        toast.success("Test request successful sent !");
      } else {
        toast.error(res?.error);
      }
    });
  };
  return loading ? <Loader /> :(
    <div>
      <BreadCrumbs routes={breadCrumb} />

      <div className="order-test-and-header-container">
        <h1 className="font-bold text-4xl tracking-tighter mb-10">
          {project?.name}
        </h1>

        <Button className="order-test-btn" onClick={() => setRequestTest(true)}>
          <p>Order Test</p>
          <ChevronRightIcon className="h-6 w-6 " />
        </Button>
      </div>

      <div>
        <TabView
          data={TABS}
          contentAreaStyle={{ paddingTop: 30 }}
          onMount={(changer) => setChanger(changer)}
        />
      </div>
      {requestTest && (
        <PopModal
          close={() => setRequestTest(false)}
          title="Test Request Form"
          size={"large"}
          children={
            <TestRequestForm
              handleCreateTestOrder={handleCreateTestOrder}
              versions={project?.versions}
            />
          }
        />
      )}
    </div>
  );
}
