import React from "react";
import ZipIcon from "../../../../../SharedComponents/icons/ZipIcon";

export default function ProjectSummary({ project }) {
  const getAllMedia = () => {
    let media = [];
    project?.tests?.forEach((test) => {
      if (test?.file)
        media?.push({
          file: test?.file,
          version: test?.version?.number,
        });
    });
    return media;
  };

  const getAllLinks = () => {
    let links = [];
    project?.tests?.forEach((test) => {
      if (test?.url) {
        links.push({
          url: test?.url,
          version: test?.version?.number,
        });
      }
    });
    return links;
  };

  return (
    <div>
      <div>
        <h2 className="text-2xl mb-4">Description</h2>
        <div
          className="justify rich-text"
          dangerouslySetInnerHTML={{
            __html:
              project?.description ||
              `You have not added any description to this project`,
          }}
        ></div>
      </div>
      {getAllMedia()?.length > 0 && (
        <div className="mt-10">
          <h2 className="text-2xl mb-4">Media</h2>
          <div className="grid-repeat-4">
            {getAllMedia()?.map((media) => (
              <div
                className="elevate-float file-display-card"
                onClick={() => window.open(media?.file, "_blank")}
              >
                <ZipIcon size={80} />
                <small>v{media?.version}</small>
              </div>
            ))}
          </div>
        </div>
      )}
      {getAllLinks()?.length > 0 && (
        <div className="mt-10">
          <h2 className="text-2xl mb-4">Links</h2>
          <div className="grid-repeat-12">
            {getAllLinks()?.map((link) => (
              <div className="version-link-container">
                <p onClick={() => window.open(link?.url, "_blank")}>
                  v{link?.version}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
